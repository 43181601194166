import { createSlice } from "@reduxjs/toolkit";

export const barsSlice = createSlice({
  name: "bar",
  initialState: {
    navbar: true,
    sidebar: true,
  },
  reducers: {
    changeNavBarDisplay: (state, action) => {
      state.navbar = action.payload;
    },
    changeSideBarDisplay: (state, action) => {
      state.sidebar = action.payload;
    },
  },
});

export const { changeNavBarDisplay, changeSideBarDisplay } = barsSlice.actions;

export default barsSlice.reducer;
