import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

import "./Onboard.css";

import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  getCities,
  getGrids,
  getStates,
  getPins,
  getExistingPinInfo,
} from "../../modules/subscriber.modules";
import {
  addEnabler,
  getMasterEnabler,
  postEnablerPinMaping,
  postpropertyEnabler,
} from "../../modules/enablers.module";

const Onboard = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const { type } = useParams();

  const [readOnlyDate, setReadOnlyDate] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [grids, setGrids] = useState([]);
  const [pins, setPins] = useState([]);
  const [masterEnabler, setMasterEnabler] = useState(null);
  const [selectedPin, setSelectedPin] = useState([]); //holds the id of the given pin
  const [enablerDetails, setEnablerDetails] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    gender: "",
    birth_date: "",
    profile_image: "",
    rc_image: "",
    dl_image: "",
    address: "",
    bike_model: "",
    rc_number: "",
    dl_number: "",
    phone_model: "",
    imei_number: "",
    camera: "",
    service_grid_id: "",
    master_enabler_id: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  let enablerData = new FormData();

  useEffect(() => {
    dispatch(changeActive(type === "enabler" ? "enabler" : "master"));

    try {
      getStates(user.user_id, token, (error, states) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (states) {
          setStates(states.data);
        }
      });
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectInputRef = useRef();

  const onClear = () => {
    //clears all selected pins
    selectInputRef.current &&
      pins.length &&
      selectInputRef.current.clearValue();
  };

  const handleStateChange = (e) => {
    if (e.target.value !== "") {
      onClear();
      try {
        getCities(user.user_id, e.target.value, token, (error, cities) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (cities) {
            setCities([]);
            setCities(cities.data);
            setGrids([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCities([]);
      setGrids([]);
    }
  };

  const handleCityChange = (e) => {
    setSelectedOption(null);
    if (e.target.value !== "") {
      try {
        getGrids(user.user_id, e.target.value, token, (error, grids) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (grids) {
            setGrids([]);
            setGrids(grids.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
    }
  };

  const renameKeys = (keysMap, obj) => {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {}
    );
  };

  const handleGridChange = (e) => {
    if (e.target.value !== "") {
      try {
        getMasterEnabler(
          user.user_id,
          e.target.value,
          token,
          (error, masterEnabler) => {
            if (error) {
              console.log(error);
              alert(`${error.code} - ${error.message}`);
            } else if (masterEnabler) {
              //api called sucessfully
              if (masterEnabler.data === undefined) {
                setMasterEnabler(null);
                setEnablerDetails({
                  ...enablerDetails,
                  service_grid_id: e.target.value,
                  master_enabler_id: "",
                });
              } else {
                setMasterEnabler(masterEnabler.data);
                setEnablerDetails({
                  ...enablerDetails,
                  service_grid_id: e.target.value,
                  master_enabler_id: masterEnabler.data.id,
                });
              }

              //API to call pins for a given grid.
              try {
                //call api to list all the pins for a given grid
                getPins(user.user_id, e.target.value, token, (error, pins) => {
                  if (error) {
                    console.log(error);
                    alert(`${error.code} - ${error.message}`);
                  } else if (pins) {
                    //change pin object Id
                    let newKeys = {
                      id: "id",
                      area_name: "value",
                      pin_code: "label",
                    };
                    //function to rename keys
                    let valarr = pins.data.map((val) => {
                      return renameKeys(newKeys, val);
                    });
                    setPins(valarr);
                  }
                });
              } catch (error) {
                console.log(error);
              }
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
    }
  };

  const handlePinChange = (e) => {
    //e is array of selected pins
    console.log(e, "selected pins");

    //change value and label keys
    let newKeys = { id: "id", value: "area_name", label: "pin_code" };

    //function to rename keys
    let valarr = e.map((val) => {
      return renameKeys(newKeys, val);
    });

    /*
        valarr.map(){will see if service_pin_id exists in enabler_mapper;
        if it exists throw error for a given pin_id & onClear();} 
        else setSelectedPin(array of pin_ids)
      */
    valarr.map((val) => {
      try {
        getExistingPinInfo(user.user_id, val.id, token, (error, grids) => {
          console.log(error, grids);
          if (grids.data == 1) {
            alert("Enabler for this Pin already exists");
            onClear();
          } else {
            return;
          }
        });
      } catch (e) {
        console.log(e);
      }
    });

    //set array of selected pins
    setSelectedPin(valarr);

    //save it in pins> which will be maped later on line 631
  };

  const handleChange = (e) => {
    if (e.target.value.trim() == "") {
      setEnablerDetails({ ...enablerDetails, [e.target.name]: "" });
    } else {
      setEnablerDetails({ ...enablerDetails, [e.target.name]: e.target.value });
    }
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value.length > 10 && parseInt(e.target.value)) {
      setEnablerDetails({
        ...enablerDetails,
        [e.target.name]: e.target.value.slice(0, 10),
      });
    } else {
      setEnablerDetails({
        ...enablerDetails,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };

  const handleReset = (field) => {
    setEnablerDetails({ ...enablerDetails, [field]: "" });
  };

  const handlePhoto = (e) => {
    setEnablerDetails({
      ...enablerDetails,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === "master") {
      if (masterEnabler !== null) {
        alert("Master Enabler already assigned to this Area");
        return;
      }
    } else {
      if (masterEnabler === null) {
        alert("No Master Enabler assigned to this Area");
        return;
      }
    }

    if (enablerDetails.profile_image instanceof File === false) {
      alert("please select a profile picture");
      return;
    }

    if (enablerDetails.birth_date) {
      let date = moment(enablerDetails.birth_date);
      console.log("I ran");
      if (date.isValid()) {
        if (Math.abs(moment().diff(date, "years")) > 100) {
          alert("Invalid Date");
          return;
        }
      } else {
        alert("Invalid Date");
        return;
      }
    }

    Object.entries(enablerDetails).forEach(([key, value]) => {
      enablerData.append(key, value);
    });

    enablerData.append("user_id", user.user_id);
    enablerData.append("role_id", type === "enabler" ? 3 : 2);

    try {
      //set loader
      setLoader(true);

      addEnabler(enablerData, token, (error, receivedData) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (receivedData) {
          //forEach selectedPin call postEnablerPinMaping
          let flag = 0;
          if (type === "enabler") {
            selectedPin &&
              selectedPin.map(async (pin) => {
                try {
                  await postEnablerPinMaping(
                    user.user_id,
                    receivedData.data.id,
                    pin.id,
                    token,
                    (error, status) => {
                      setLoader(false);
                      if (error) {
                        flag = 1;
                        console.log(error);
                        return;
                      } else {
                        return;
                      }
                    }
                  );
                } catch (e) {
                  setLoader(false);
                  console.log(e);
                }
              }); //end of map
            {
              postpropertyEnabler(
                user.user_id,
                selectedPin[0].id,
                token,
                (error, success) => {}
              );
            }
          }
          if (flag == 0 || type !== "enabler") {
            setLoader(false);
            alert("data saved sucessfully");
            window.location.reload();
          } else {
            setLoader(false);
            alert("something went wrong");
          }

          //refresh
          let val = window.location.reload();
          console.log(val);
          setGrids([]);
          setSelectedPin([]);
          setPins([]);
          setCities([]);
          //refresh
          window.location.href = window.location.href;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (loader) {
    //loading
    return (
      <div className="center-loader">
        <div class="lds-hourglass"></div>
      </div>
    );
  } else {
    return (
      <>
        {/* <NavBar /> */}
        <Container fluid className="main-page-container">
          <Row>
            {/* <Col className="main-page-sidebar-container" xs="auto">
              <SideBar />
            </Col> */}
            <Col className="main-page-parent-content-container">
              {type === "enabler" || type === "master" ? (
                <Container className="main-page-content-container">
                  <Form onSubmit={handleSubmit}>
                    <Row className="onboard-page-container">
                      {type === "enabler" && (
                        <p className="onboard-type-title">ENABLER'S DETAILS</p>
                      )}
                      {type === "master" && (
                        <p className="onboard-type-title">
                          MASTER ENABLER'S DETAILS
                        </p>
                      )}
                      <Row>
                        <p>
                          <b>Personal Information</b>
                        </p>
                        <Col xs={5}>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Eg John Doe"
                                name="full_name"
                                value={enablerDetails.full_name}
                                onChange={handleChange}
                                required
                                tabindex="1"
                              />
                              <InputGroup.Text
                                onClick={() => handleReset("full_name")}
                                className="onboard-clear-button"
                              >
                                x
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="email"
                                placeholder="Eg abc@gmail.com"
                                name="email"
                                value={enablerDetails.email}
                                onChange={handleChange}
                                required
                                tabindex="3"
                              />
                              <InputGroup.Text
                                onClick={() => handleReset("email")}
                                className="onboard-clear-button"
                              >
                                x
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={5}>
                          <Form.Group className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <InputGroup className="FormControl">
                              <Form.Control
                                className="FormControl"
                                type="number"
                                placeholder="Eg 7903147842"
                                name="phone_number"
                                value={enablerDetails.phone_number}
                                onChange={(e) => {
                                  handleChange(e);
                                  handlePhoneNumber(e);
                                }}
                                required
                                tabindex="2"
                              />
                              <InputGroup.Text
                                onClick={() => handleReset("phone_number")}
                                className="onboard-clear-button"
                              >
                                x
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                  name="gender"
                                  value={enablerDetails.gender}
                                  onChange={handleChange}
                                  required
                                  tabindex="4"
                                >
                                  <option value="">Choose Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Others</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Date of Birth</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="date"
                                    name="birth_date"
                                    id="date-field"
                                    value={enablerDetails.birth_date}
                                    onChange={handleChange}
                                    required
                                    tabindex="5"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("birth_date")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={2}
                          className="onboard-upload-container image-border-upload"
                        >
                          <Form.Group id="upload_button">
                            <Form.Label>
                              <Form.Control
                                type="file"
                                accept=".png,.jpg"
                                name="profile_image"
                                onChange={handlePhoto}
                              />
                              {enablerDetails.profile_image instanceof File ? (
                                <img
                                  className="onboard-upload-preview"
                                  src={URL.createObjectURL(
                                    enablerDetails.profile_image
                                  )}
                                  alt="profie pic"
                                />
                              ) : (
                                <FontAwesomeIcon icon={faUser} size="6x" />
                              )}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={10}>
                          <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Eg St. road Patratu"
                                name="address"
                                value={enablerDetails.address}
                                onChange={handleChange}
                                required
                                tabindex="6"
                              />
                              <InputGroup.Text
                                onClick={() => handleReset("address")}
                                className="onboard-clear-button"
                              >
                                x
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          <b>Bike Information</b>
                        </p>
                        <Col xs={10}>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Bike Model</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg CD Deluxe"
                                    name="bike_model"
                                    value={enablerDetails.bike_model}
                                    onChange={handleChange}
                                    required
                                    tabindex="7"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("bike_model")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>RC Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg RC34324254543"
                                    name="rc_number"
                                    value={enablerDetails.rc_number}
                                    onChange={handleChange}
                                    required
                                    tabindex="8"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("rc_number")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>DL Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg DL23532414213"
                                    name="dl_number"
                                    value={enablerDetails.dl_number}
                                    onChange={handleChange}
                                    required
                                    tabindex="9"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("dl_number")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          <b>Phone Information</b>
                        </p>
                        <Col xs={10}>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone Model</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg Mi note 8"
                                    name="phone_model"
                                    value={enablerDetails.phone_model}
                                    onChange={handleChange}
                                    required
                                    tabindex="10"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("phone_model")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>IMEI Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg 34324254543"
                                    name="imei_number"
                                    value={enablerDetails.imei_number}
                                    onChange={handleChange}
                                    required
                                    tabindex="11"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("imei_number")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Camera</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg 48mp"
                                    name="camera"
                                    value={enablerDetails.camera}
                                    onChange={handleChange}
                                    required
                                    tabindex="12"
                                  />
                                  <InputGroup.Text
                                    onClick={() => handleReset("camera")}
                                    className="onboard-clear-button"
                                  >
                                    x
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          <b>KYC information</b>
                        </p>
                        <Col xs="4">
                          <Form.Group className="mb-3">
                            <Form.Label>RC Copy</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              name="rc_image"
                              onChange={handlePhoto}
                              required
                              tabindex="13"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs="4">
                          <Form.Group className="mb-3">
                            <Form.Label>DL Copy</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              name="dl_image"
                              onChange={handlePhoto}
                              required
                              tabindex="14"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <p>
                          <b>Grid Assignment</b>
                        </p>

                        <Col xs={10}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Label className="primary-text-color">
                                State
                              </Form.Label>
                              <Form.Select
                                name="state"
                                onChange={handleStateChange}
                                tabindex="15"
                              >
                                <option value="">Select a state</option>
                                {states.map((item, index) => (
                                  <option key={item.id} value={item.id}>
                                    {item.state_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label className="primary-text-color">
                                City
                              </Form.Label>
                              <Form.Select
                                name="city"
                                onChange={handleCityChange}
                                tabindex="16"
                              >
                                <option value="">Select a city</option>
                                {cities.map((item, index) => (
                                  <option key={item.id} value={item.id}>
                                    {item.city_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label className="primary-text-color">
                                Grid
                              </Form.Label>
                              <Form.Select
                                name="service_grid_id"
                                onChange={handleGridChange}
                                required
                                tabindex="17"
                              >
                                <option value="">Select a grid</option>
                                {grids.map((item, index) => (
                                  <option key={item.id} value={item.id}>
                                    {item.grid_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {type === "enabler" && (
                              <Row className="mt-3">
                                <Form.Group as={Row}>
                                  <Form.Label className="primary-text-color">
                                    Pin
                                  </Form.Label>

                                  <Select
                                    defaultValue={selectedOption}
                                    onChange={(e) => {
                                      setSelectedOption();
                                      handlePinChange(e);
                                    }}
                                    ref={selectInputRef}
                                    options={pins}
                                    isMulti
                                  />
                                </Form.Group>
                              </Row>
                            )}
                          </Row>
                        </Col>
                        <Col className="onboard-save-button-container" xs={2}>
                          {type === "master" && (
                            <Button className="medium-button" type="submit">
                              SAVE
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={10}>
                          {type === "enabler" && (
                            <Form.Group as={Col}>
                              <Form.Label className="primary-text-color">
                                Master Enabler
                              </Form.Label>
                              {/* <Form.Select
                                    name="master_enabler_id"
                                    onChange={handleChange}
                                  >
                                    <option value="">Select master enabler</option>
                                    {masterEnablers.map((item, index) => (
                                      <option key={item.id} value={item.id}>
                                        {item.full_name}
                                      </option>
                                    ))}
                                  </Form.Select> */}
                              <br />
                              <Form.Label className="onboard-master-enabler-name">
                                {masterEnabler !== null ? (
                                  masterEnabler.full_name
                                ) : (
                                  <span className="onboard-master-enabler-name-empty">
                                    No Master Enabler assigned
                                  </span>
                                )}
                              </Form.Label>
                            </Form.Group>
                          )}
                        </Col>
                        <Col className="onboard-save-button-container" xs={2}>
                          {type === "enabler" && (
                            <Button className="medium-button" type="submit">
                              SAVE
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                  <p className="onboard-note">
                    Note : All the fields are mandatory including profile
                    picture, RC & DL Doc.
                  </p>
                </Container>
              ) : (
                <h2>The page you are looking for doesn't exist :( </h2>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default Onboard;
