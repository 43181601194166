import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./KYCDetailsCard.css";

import {
  Container,
  Row,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import KYCDetailsLogo from "../../assests/kycdetails.png";
import KYCModal from "../KYCDetailsModal/KYCModal";
import { updateKYCReqStatus } from "../../modules/subscriber.modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const KYCDetailsCard = (props) => {
  const [show, setShow] = useState(false);
  const kycDetails = props.details;
  const [rejectionReason, setRejectionReason] = useState("");

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);

  const handleAccept = () => {
    try {
      updateKYCReqStatus(
        user.user_id,
        kycDetails.id,
        null,
        2,
        token,
        (error, updateDone) => {
          if (error) {
            console.log(error);
            alert(error.message);
          } else if (updateDone) {
            alert("KYC Request Accepted!!!");
            props.handleSearch();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = () => {
    if (rejectionReason === "") {
      alert("Please give a rejection reason.");
      return;
    }
    try {
      updateKYCReqStatus(
        user.user_id,
        kycDetails.id,
        rejectionReason,
        3,
        token,
        (error, updateDone) => {
          if (error) {
            console.log(error);
            alert(error.message);
          } else if (updateDone) {
            alert("KYC Request Rejected!!!");
            props.handleSearch();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChoice = (fn, str) => {
    let value = window.confirm(str);
    if (value) {
      fn();
      return;
    } else {
      return;
    }
  };

  const renderTooltip = (address) => (
    <Tooltip id="button-tooltip">{address}</Tooltip>
  );

  return (
    <Container className="kyc-details-card-container">
      <Row className="kyc-details-card-row">
        <div
          className={`kyc-details-card-col-indicator ${
            kycDetails.status === "pending"
              ? "kyc-details-card-col-pending-indicator"
              : ""
          } ${
            kycDetails.status === "accepted"
              ? "kyc-details-card-col-accepted-indicator"
              : ""
          } ${
            kycDetails.status === "rejected"
              ? "kyc-details-card-col-rejected-indicator"
              : ""
          }`}
        ></div>
        <div className="kyc-details-card-content">
          <p className="kyc-details-card-username">
            {kycDetails.User.full_name}
            <br />
            <span className="kyc-details-card-username-details">
              {kycDetails.User.phone_number}, {kycDetails.User.email}
              <br />
              {kycDetails.User.address === null ||
              kycDetails.User.address.length <= 40 ? (
                kycDetails.User.address
              ) : (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(kycDetails.User.address)}
                >
                  <span>{`${kycDetails.User.address.substring(
                    0,
                    40
                  )}...`}</span>
                </OverlayTrigger>
              )}
            </span>
          </p>
          <p className="kyc-details-card-username">
            Requested At: {formatDate(kycDetails.createdAt)}
          </p>
        </div>
        <div className="kyc-details-card-logo-container">
          <img
            className="kyc-details-card-logo"
            src={KYCDetailsLogo}
            onClick={() => setShow(true)}
            alt="KYCDetailsLogo"
          />
        </div>
      </Row>
      <div>
        <Modal
          contentClassName="kyc-details-card-modal"
          size="lg"
          show={show}
          centered
          onHide={() => setShow(false)}
        >
          <Modal.Body className="p-4">
            <Container className="kyc-details-card-modal-button-container">
              <FontAwesomeIcon
                className="primary-icon-color kyc-details-card-modal-close-button"
                icon={faTimesCircle}
                onClick={() => setShow(false)}
                size="2x"
              />
            </Container>
            <KYCModal details={props.details} />
            {kycDetails.status === "pending" ? (
              <Container className="kyc-modal-buttons-row">
                <Form.Select
                  onChange={(e) => setRejectionReason(e.target.value)}
                >
                  <option value="">Other</option>
                  <option value="Kyc not clear due to light/ it is not within camera bracket.">
                    Kyc not clear due to light/ it is not within camera bracket.
                  </option>
                  <option value="Face not clear due to light / it is not within bracket.">
                    Face not clear due to light / it is not within bracket.
                  </option>
                  <option value="Selfie with Kyc not clear due to light/ it is not within bracket.">
                    Selfie with Kyc not clear due to light/ it is not within
                    bracket.
                  </option>
                </Form.Select>
                &nbsp;
                <Form.Control
                  type="text"
                  placeholder="Rejection reason"
                  name="reject"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                />
                <Button
                  className="medium-button-danger kyc-modal-button"
                  onClick={() => {
                    handleChoice(
                      handleReject,
                      "Are you sure you want to Reject"
                    );
                  }}
                >
                  Reject
                </Button>
                <Button
                  className="medium-button kyc-modal-button"
                  onClick={() => {
                    handleChoice(
                      handleAccept,
                      "Are you sure you want to Accept"
                    );
                  }}
                >
                  Accept
                </Button>
              </Container>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default KYCDetailsCard;
