import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format, getDate } from "date-fns";
import moment from "moment";
import "./fortnight.css";

function Fortnightly({
  selectedDay,
  setSelectedDay,
  selectedSecondDay,
  setSelectedSecondDay,
}) {
  let [days, setDays] = useState(null);

  let date = new Date();
  let footer;
  const disabledDays = [
    { from: new Date(2000, 4, 18), to: date.setDate(date.getDate()) },
  ];
  return (
    <>
      <DayPicker
        mode="multiple"
        showOutsideDays
        max={2}
        min={0}
        defaultMonth={new Date()}
        disabled={disabledDays}
        selected={days}
        onSelect={setDays}
        footer={footer}
        className="dayPicker"
      />
      {days && days.length === 1 ? setSelectedDay(days[0]) : ""}
      {selectedDay && !selectedSecondDay
        ? `Date Selected - ${format(selectedDay, "dd/MM/yyyy")} and ${moment(
            selectedDay
          )
            .add(15, "days")
            .format("DD/MM/YYYY")}  .`
        : ``}
      {days && days.length === 2
        ? (setSelectedDay(days[0]), setSelectedSecondDay(days[1]))
        : ""}
      {
        (footer =
          selectedDay && selectedSecondDay
            ? `Date Selected - ${format(
                selectedDay,
                "dd/MM/yyyy"
              )} and ${format(selectedSecondDay, "dd/MM/yyyy")} .`
            : ``)
      }
    </>
  );
}

export default Fortnightly;
