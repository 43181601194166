import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "./Tutorials.css";

import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";

const Tutorials = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeActive("tutorials"));
  });

  return (
    <>
      <NavBar />
      <Container fluid className="main-page-container">
        <Row>
          <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col>
          <Col className="main-page-parent-content-container">
            <Container className="main-page-content-container"></Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tutorials;
