import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

function weekly({ selectedDay, setSelectedDay }) {
  let date = new Date();
  let footer;
  const disabledDays = [
    { from: new Date(2000, 4, 18), to: date.setDate(date.getDate()) },
  ];
  return (
    <>
      <DayPicker
        showOutsideDays
        defaultMonth={new Date()}
        disabled={disabledDays}
        mode="single"
        selected={selectedDay}
        onSelect={setSelectedDay}
        footer={footer}
        className="dayPicker"
      />

      {
        (footer = selectedDay
          ? `Date Selected - ${format(selectedDay, "dd/MM/yyyy")} .`
          : `Please pick a date.`)
      }
    </>
  );
}

export default weekly;
