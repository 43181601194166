import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { saveAs } from "file-saver";

import "./DashBoard.css";

// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";
import {
  changeNavBarDisplay,
  changeSideBarDisplay,
} from "../../redux/features/bars.slice";
import {
  getDashboardAnayltics,
  getDashboardDetails,
  getExportData,
} from "../../modules/admin.module";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import DashboardGraph from "../../components/DashboardGraph/DashboardGraph";

const DashBoard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(true);

  const [dashboardDetails, setDashboardDetails] = useState({});
  const [analytics, setAnalytics] = useState([]);
  const [kycData, setKycData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [moderationData, setModerationData] = useState([]);
  const [geofencingData, setGeofencingData] = useState([]);

  useEffect(() => {
    dispatch(changeActive("dashboard"));
    dispatch(changeNavBarDisplay(true));
    dispatch(changeSideBarDisplay(true));
    getDashboardDetails(user.user_id, token, (error, details) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (details) {
        setDashboardDetails(details.data);
      }
    });

    getDashboardAnayltics((error, details) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (details) {
        setAnalytics(details.data);
        setKycData([
          { name: "KYC Accepted Today", value: details.data.kycAcceptedToday },
          { name: "KYC Rejected Today", value: details.data.kycRejectedToday },
          { name: "Total KYC Pending", value: details.data.kycPending },
        ]);
        setGeofencingData([
          {
            name: "Geofencing Pending_By Subscribers",
            value: details.data.geofencingPendingBySubscribers,
          },
          {
            name: "Geofencing Pending_By Enablers",
            value: details.data.geofencingPendingByEnablers,
          },
        ]);

        setModerationData([
          {
            name: "Moderations Done Today",
            value: details.data.moderationsDoneToday,
          },
          {
            name: "Moderations Pending Today",
            value: details.data.moderationsPendingToday,
          },
        ]);

        setReportData([
          {
            name: "Reports Done Today",
            value: details.data.reportsDoneToday,
          },
          {
            name: "Reports Pending Today",
            value: details.data.reportsPendingToday,
          },
        ]);

        setLoading(false);
      }
    });
  }, []);

  const handleDownload = async () => {
    try {
      alert("Export started!!! Please wait for sometime...");

      getExportData(async (error, response) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (response) {
          console.log(response);
          const fileName =
            response.headers["content-disposition"].split("=")[1];
          saveAs(response.data, fileName);
          alert("Export completed!!!");
        }
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            {loading ? (
              <h3>Loading ...</h3>
            ) : (
              <Container fluid className="main-page-content-container">
                <Row>
                  <Col>
                    <p className="dashboard-title">DASHBOARD</p>
                  </Col>
                  <Col>
                    <FontAwesomeIcon
                      onClick={handleDownload}
                      className="dashboard-download-button"
                      icon={faFileDownload}
                    />
                    <p className="dashboard-download-text">Export Analytics:</p>
                  </Col>
                </Row>
                <Row className="dashboard-analytics-box">
                  {/* <p className="dashboard-title">ANALYTICS</p> */}
                  <div className="dashboard-analytics-user-details-box">
                    <CustomLabel
                      title="Total Active Users"
                      value={analytics.activeUsers}
                    />
                    <CustomLabel
                      title="Users Added Yesterday"
                      value={analytics.userAddedYesterday}
                    />
                    <CustomLabel
                      title="Users Added This Month"
                      value={analytics.userAddedThisMonth}
                    />
                  </div>

                  <div className="dashboard-graph-outer-box">
                    <DashboardGraph
                      data={kycData}
                      title="KYC"
                      label="Total KYC Pending"
                      value={analytics?.kycPending}
                    />
                    <DashboardGraph
                      data={geofencingData}
                      title="GEOFENCING"
                      label="Total Geofencing Pending"
                      value={
                        analytics?.geofencingPendingBySubscribers +
                        analytics?.geofencingPendingByEnablers
                      }
                    />
                    <DashboardGraph
                      data={moderationData}
                      title="MODERATIONS"
                      label="Total Scheduled"
                      value={
                        analytics?.moderationsDoneToday +
                        analytics?.moderationsPendingToday
                      }
                    />
                    <DashboardGraph
                      data={reportData}
                      title="REPORTS"
                      label="Total Scheduled"
                      value={analytics?.reportsScheduledToday}
                    />
                  </div>
                </Row>
                {/* <Row>
                  <Col>
                    <LineChart
                      width={750}
                      height={500}
                      data={dashboardDetails?.statistics}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="scheduled"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="completed"
                        stroke="#82ca9d"
                      />
                    </LineChart>
                    <center>
                      <p className="dashboard-title">Reports</p>
                    </center>
                  </Col>
                  <Col>
                    <Row className="dashboard-statistics-box">
                      <Col>
                        <p className="dashboard-title">
                          {moment().format("MMM-YYYY")}
                        </p>
                      </Col>

                      <Col>
                        <FontAwesomeIcon
                          onClick={handleDownload}
                          className="dashboard-download-button"
                          icon={faFileDownload}
                        />
                        <p className="dashboard-download-text">
                          Export Analytics:
                        </p>
                      </Col>
                    </Row>
                    <Row className="dashboard-statistics-user-box">
                      <Container className="dashboard-statistics-user-box-container">
                        <p className="dashboard-text">
                          New Subscribers:{" "}
                          {
                            dashboardDetails?.users?.filter(
                              (item) => item.role_id === 4
                            ).length
                          }
                        </p>

                        {dashboardDetails?.users?.filter(
                          (item) => item.role_id === 4
                        ).length ? (
                          <p className="dashboard-text">
                            Recent Subscribers:
                            <br />
                            {dashboardDetails?.users
                              ?.filter((item) => item.role_id === 4)
                              .reverse()
                              .slice(0, 3)
                              .map((user) => (
                                <span>
                                  + {user.full_name} <br />
                                </span>
                              ))}
                          </p>
                        ) : null}
                      </Container>
                    </Row>
                    <Row className="dashboard-statistics-user-box">
                      <Container className="dashboard-statistics-user-box-container">
                        <p className="dashboard-text">
                          New Enablers:{" "}
                          {
                            dashboardDetails?.users?.filter(
                              (item) => item.role_id === 3
                            ).length
                          }
                        </p>
                        {dashboardDetails?.users?.filter(
                          (item) => item.role_id === 3
                        ).length ? (
                          <p className="dashboard-text">
                            Recent Subscribers:
                            <br />
                            {dashboardDetails?.users
                              ?.filter((item) => item.role_id === 3)
                              .reverse()
                              .slice(0, 3)
                              .map((user) => (
                                <span>
                                  {user.full_name} <br />
                                </span>
                              ))}
                          </p>
                        ) : null}
                      </Container>
                    </Row>
                    <Row className="dashboard-statistics-user-box">
                      <Container className="dashboard-statistics-user-box-container">
                        <p className="dashboard-text">
                          New Master Enablers:{" "}
                          {
                            dashboardDetails?.users?.filter(
                              (item) => item.role_id === 2
                            ).length
                          }
                        </p>

                        {dashboardDetails?.users?.filter(
                          (item) => item.role_id === 2
                        ).length ? (
                          <p className="dashboard-text">
                            Recent Subscribers:
                            <br />
                            {dashboardDetails?.users
                              ?.filter((item) => item.role_id === 2)
                              .reverse()
                              .slice(0, 3)
                              .map((user) => (
                                <span>
                                  {user.full_name} <br />
                                </span>
                              ))}
                          </p>
                        ) : null}
                      </Container>
                    </Row>
                  </Col>
                </Row> */}
              </Container>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashBoard;
