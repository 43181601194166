import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import "./UpdateEnabler.css";

import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faUser } from "@fortawesome/free-solid-svg-icons";
import config from "../../config/config";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import {
  getCities,
  getGrids,
  getStates,
  getPins,
  getExistingPinInfo,
} from "../../modules/subscriber.modules";
import {
  getEnablerDetails,
  getMasterEnabler,
  updateEnabler,
  getEnablerPins,
  deleteRelationEnablerPin,
  postEnablerPinMaping,
  updatePropertyEnabler,
} from "../../modules/enablers.module";

const UpdateEnabler = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const { type, id } = useParams();

  const [isFetched, setIsFetched] = useState(false);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [enablerId, setEnablerId] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [grids, setGrids] = useState([]);
  const [readOnlyDate, setReadOnlyDate] = useState(edit);
  const [pins, setPins] = useState([]);
  const [selectedSubmitPin, setSelectedSubmitPin] = useState(null);
  const [masterEnabler, setMasterEnabler] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStringPins, setSelectedStringPins] = useState(null);
  const [selectedPin, setSelectedPin] = useState([]); //holds the id of the given pin
  const [enablerPinActive, setEnablerPinActive] = useState([]); //newly fetched pin values
  const [receivedDetails, setReceivedDetails] = useState({
    Service_Grid: {
      grid_name: "",
      Service_City: { Service_State: { state_name: "" }, city_name: "" },
    },
    M_Enabler: { id: "", full_name: "" },
    address: "",
    bike_model: "",
    birth_date: "",
    dl_image: "",
    dl_number: "",
    phone_model: "",
    imei_number: "",
    camera: "",
    email: "",
    full_name: "",
    gender: "",
    id: "",
    master_enabler_id: "",
    service_grid_id: "",
    phone_number: "",
    profile_image: "",
    rc_image: "",
    rc_number: "",
  });

  let enablerData = new FormData();

  useEffect(() => {
    dispatch(changeActive(type === "enabler" ? "enabler" : "master"));

    try {
      getEnablerDetails(user.user_id, id, token, (error, enablerDetails) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (enablerDetails) {
          //got enabler details
          try {
            setEnablerId(enablerDetails.data.id);
            getEnablerPins(
              user.user_id,
              enablerDetails.data.id,
              token,
              (error, pinDetails) => {
                if (error) {
                  console.log(error, "enabler pins");
                  alert(`${error.code} - ${error.message}`);
                } else if (pinDetails) {
                  //all selected pins for a given userId

                  //convert to fit dropdown
                  let newKeys = {
                    id: "id",
                    area_name: "value",
                    pin_code: "label",
                  };
                  let valarr = pinDetails.data.map((val) => {
                    return renameKeys(newKeys, val.Service_Pin);
                  });
                  //save in setEnablerPinActive
                  setEnablerPinActive(valarr);
                  setSelectedStringPins(
                    valarr
                      .map((val) => {
                        return val.label;
                      })
                      .join(" , ")
                  );
                }
              }
            );
          } catch (e) {
            console.log(e, "error");
          }
          //getEnablerPins()-> get all pins -> save it to setEnablerPinActive-> delete all pins for a given enabler in enabler_mapper ->
          setReceivedDetails(enablerDetails.data);
          setIsFetched(true);
          //get all pins for a given enabler
        }
      });
    } catch (error) {
      console.log(error);
    }

    try {
      getStates(user.user_id, token, (error, states) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (states) {
          setStates(states.data);
        }
      });
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (edit == true) {
  //     //delete all pins for a given enablerId in enabler_mappings
  //     try {
  //       deleteRelationEnablerPin(
  //         user.user_id,
  //         enablerId,
  //         token,
  //         (error, pinDetails) => {
  //           if (error) {
  //             console.log(error);
  //             alert(`${error.code} - ${error.message}`);
  //           } else if (pinDetails) {
  //             return;
  //           }
  //         }
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   } else {
  //     return;
  //   }
  // }, [edit]);

  const selectInputRef = useRef();

  const onClear = () => {
    //clears all selected pins
    pins.length && selectInputRef.current.clearValue();
  };

  const renameKeys = (keysMap, obj) => {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {}
    );
  };
  const handleStateChange = (e) => {
    onClear();
    if (e.target.value !== "") {
      try {
        getCities(user.user_id, e.target.value, token, (error, cities) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (cities) {
            setCities([]);
            setCities(cities.data);
            setGrids([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCities([]);
      setGrids([]);
    }
  };

  const handleCityChange = (e) => {
    onClear();
    if (e.target.value !== "") {
      try {
        getGrids(user.user_id, e.target.value, token, (error, grids) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (grids) {
            setGrids([]);
            setGrids(grids.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
    }
  };

  const handleGridChange = (e) => {
    onClear();
    if (e.target.value !== "") {
      try {
        getMasterEnabler(
          user.user_id,
          e.target.value,
          token,
          (error, masterEnabler) => {
            if (error) {
              console.log(error);
              alert(`${error.code} - ${error.message}`);
            } else if (masterEnabler) {
              if (masterEnabler.data === undefined) {
                setMasterEnabler(null);
                setReceivedDetails({
                  ...receivedDetails,
                  service_grid_id: e.target.value,
                  master_enabler_id: "",
                });
              } else {
                setMasterEnabler(masterEnabler.data);
                setReceivedDetails({
                  ...receivedDetails,
                  service_grid_id: e.target.value,
                  master_enabler_id: masterEnabler.data.id,
                });
              }
              try {
                //call api to list all the pins for a given grid
                getPins(user.user_id, e.target.value, token, (error, pins) => {
                  if (error) {
                    console.log(error);
                    alert(`${error.code} - ${error.message}`);
                  } else if (pins) {
                    //change pin object Id
                    let newKeys = {
                      id: "id",
                      area_name: "value",
                      pin_code: "label",
                    };
                    //function to rename keys
                    let valarr = pins.data.map((val) => {
                      return renameKeys(newKeys, val);
                    });
                    setPins(valarr);
                  }
                });
              } catch (error) {
                console.log(error);
              }
            } else {
              setGrids([]);
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
    }
  };

  const handlePinChange = (e) => {
    //e is array of selected pins
    setSelectedSubmitPin(e);

    //change value and label keys
    let newKeys = { id: "id", value: "area_name", label: "pin_code" };

    //function to rename keys
    let valarr = e.map((val) => {
      return renameKeys(newKeys, val);
    });
    /*
        valarr.map(){will see if service_pin_id exists in enabler_mapper;
        if it exists throw error for a given pin_id & onClear();} 
        else setSelectedPin(array of pin_ids)
      */

    valarr.map((val) => {
      try {
        getExistingPinInfo(user.user_id, val.id, token, (error, grids) => {
          if (grids.data == 1) {
            alert("Enabler for this Pin already exists");
            onClear();
          } else {
            return;
          }
        });
      } catch (e) {
        console.log(e);
      }
    });

    //set array of selected pins
    setSelectedPin(valarr);
    //save it in pins> which will be maped later on line 631
  };
  const handleChange = (e) => {
    if (e.target.value.trim() == "") {
      setReceivedDetails({ ...receivedDetails, [e.target.name]: "" });
    } else {
      setReceivedDetails({
        ...receivedDetails,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value.length > 10 && parseInt(e.target.value)) {
      setReceivedDetails({
        ...receivedDetails,
        [e.target.name]: e.target.value.slice(0, 10),
      });
    } else {
      setReceivedDetails({
        ...receivedDetails,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };

  const handleReset = (field) => {
    setReceivedDetails({ ...receivedDetails, [field]: "" });
  };

  const handlePhoto = (e) => {
    setReceivedDetails({
      ...receivedDetails,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (receivedDetails.birth_date) {
      let date = moment(receivedDetails.birth_date);
      if (date.isValid()) {
        if (Math.abs(moment().diff(date, "years")) > 100) {
          alert("Invalid Date");
          return;
        }
      } else {
        alert("Invalid Date");
        return;
      }
    }
    Object.entries(receivedDetails).forEach(([key, value]) => {
      if (value !== "" && value !== null && key !== "Service_Grid") {
        enablerData.append(key, value);
      }
    });

    enablerData.append("user_id", user.user_id);
    enablerData.append("enabler_id", id);
    enablerData.append("type", type);

    try {
      //if ENABLER
      if (type == "enabler") {
        //ENABLER
        selectedPin.length
          ? updateEnabler(enablerData, token, (error, receivedData) => {
              if (error) {
                console.log(error);
                alert(`${error.code} - ${error.message}`);
              } else if (receivedData) {
                /*All about the pins*/
                //convert the selected pins back to its original state.
                let newKeys = {
                  id: "id",
                  value: "area_name",
                  label: "pin_code",
                };

                //function to rename keys
                let valarr = selectedSubmitPin.map((val) => {
                  return renameKeys(newKeys, val);
                });

                console.log(valarr, "updated values");

                //for each selected pin save in Enabler_mappings
                let flag = false;
                selectedPin.map((pin) => {
                  try {
                    postEnablerPinMaping(
                      user.user_id,
                      enablerId,
                      pin.id,
                      token,
                      (error, status) => {
                        if (error) {
                          flag = 1;
                          console.log(error);
                          return;
                        } else {
                          updatePropertyEnabler(
                            user.user_id,
                            status.data.service_pin_id,
                            token,
                            (error, statusResult) => {
                              console.log(error, statusResult);
                            }
                          );
                          return;
                        }
                      }
                    );
                  } catch (e) {
                    console.log(e);
                  }
                });

                if (!flag) {
                  alert("Data Saved Successfully!");
                  window.location.reload();
                  //all reports generated should be updated
                } else {
                  alert("something went wrong");
                }
              }
            })
          : alert("please fill the Grid assignment values");
      } else {
        //MASTER-ENABLER
        grids.length
          ? updateEnabler(enablerData, token, (error, receivedData) => {
              if (error) {
                console.log(error);
                alert(`${error.code} - ${error.message}`);
              } else if (receivedData) {
                if (receivedData.data.length > 0) {
                  alert("Data Saved Successfully!");
                  window.location.reload();
                  //update report generation master_enabler_id for future report scheduled date.
                } else {
                  alert("something went wrong");
                }
              }
            })
          : alert("please fill the Grid assignment values");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            {type === "enabler" || type === "master" ? (
              isFetched ? (
                <Container className="main-page-content-container">
                  <Form>
                    <Row className="update-enabler-page-container">
                      {type === "enabler" && (
                        <p className="update-enabler-type-title">
                          ENABLER'S DETAILS
                        </p>
                      )}
                      {type === "master" && (
                        <p className="update-enabler-type-title">
                          MASTER ENABLER'S DETAILS
                        </p>
                      )}
                      <Row>
                        <p>
                          <b>Personal Information</b>
                        </p>
                        <Col xs={5}>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>

                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Eg John Doe"
                                name="full_name"
                                value={receivedDetails.full_name}
                                readOnly={!edit}
                                onChange={handleChange}
                              />
                              {edit ? (
                                <InputGroup.Text
                                  onClick={() => handleReset("full_name")}
                                  className="update-enabler-clear-button"
                                >
                                  x
                                </InputGroup.Text>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="email"
                                placeholder="Eg abc@gmail.com"
                                name="email"
                                value={receivedDetails.email}
                                readOnly={!edit}
                                onChange={handleChange}
                              />
                              {edit ? (
                                <InputGroup.Text
                                  onClick={() => handleReset("email")}
                                  className="update-enabler-clear-button"
                                >
                                  x
                                </InputGroup.Text>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={5}>
                          <Form.Group className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Eg 7903147842"
                                name="phone_number"
                                value={receivedDetails.phone_number}
                                readOnly={!edit}
                                onChange={(e) => {
                                  handleChange(e);
                                  handlePhoneNumber(e);
                                }}
                                required
                              />
                              {edit ? (
                                <InputGroup.Text
                                  onClick={() => handleReset("phone_number")}
                                  className="update-enabler-clear-button"
                                >
                                  x
                                </InputGroup.Text>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>

                                <Form.Select
                                  name="gender"
                                  value={receivedDetails.gender}
                                  onChange={handleChange}
                                  disabled={!edit}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Date of Birth</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="date"
                                    name="birth_date"
                                    id="date-field"
                                    value={receivedDetails.birth_date}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("birth_date")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={2} className="update-enabler-upload-container">
                          <Form.Group id="upload_button">
                            <Form.Label>
                              <Form.Control
                                type="file"
                                accept=".png,.jpg"
                                name="profile_image"
                                onChange={handlePhoto}
                              />
                              {edit ? (
                                receivedDetails.profile_image instanceof
                                File ? (
                                  <img
                                    className="update-enabler-upload-preview"
                                    src={URL.createObjectURL(
                                      receivedDetails.profile_image
                                    )}
                                    alt="profie pic"
                                  />
                                ) : (
                                  <FontAwesomeIcon icon={faUser} size="6x" />
                                )
                              ) : null}
                            </Form.Label>
                            {edit ? null : (
                              <>
                                <Modal
                                  contentClassName=""
                                  size="lg"
                                  show={show}
                                  centered
                                  onHide={() => setShow(false)}
                                >
                                  <Modal.Body className="modla-userImage">
                                    <img
                                      className="modla-userImage-img"
                                      src={`${config.server.url}/${receivedDetails.profile_image}`}
                                      alt="enablers"
                                    />
                                  </Modal.Body>
                                </Modal>

                                <img
                                  className="update-enabler-upload-preview"
                                  src={`${config.server.url}/${receivedDetails.profile_image}`}
                                  alt="enablers"
                                  onClick={() => setShow(true)}
                                />
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={10}>
                          <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Eg St. road Patratu"
                                name="address"
                                value={receivedDetails.address}
                                readOnly={!edit}
                                onChange={handleChange}
                              />
                              {edit ? (
                                <InputGroup.Text
                                  onClick={() => handleReset("address")}
                                  className="update-enabler-clear-button"
                                >
                                  x
                                </InputGroup.Text>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          <b>Bike Information</b>
                        </p>
                        <Col xs={10}>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Bike Model</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg CD Deluxe"
                                    name="bike_model"
                                    value={receivedDetails.bike_model}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("bike_model")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>RC Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg RC34324254543"
                                    name="rc_number"
                                    value={receivedDetails.rc_number}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("rc_number")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>DL Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg DL23532414213"
                                    name="dl_number"
                                    value={receivedDetails.dl_number}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("dl_number")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          <b>Phone Information</b>
                        </p>
                        <Col xs={10}>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone Model</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg CD Deluxe"
                                    name="phone_model"
                                    value={receivedDetails.phone_model}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("phone_model")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>IMEI Number</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg RC34324254543"
                                    name="imei_number"
                                    value={receivedDetails.imei_number}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() =>
                                        handleReset("imei_numberr")
                                      }
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Camera</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Eg DL23532414213"
                                    name="camera"
                                    value={receivedDetails.camera}
                                    readOnly={!edit}
                                    onChange={handleChange}
                                  />
                                  {edit ? (
                                    <InputGroup.Text
                                      onClick={() => handleReset("camera")}
                                      className="update-enabler-clear-button"
                                    >
                                      x
                                    </InputGroup.Text>
                                  ) : null}
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <p>
                          <b>KYC information</b>
                        </p>
                        <Col xs="4">
                          <Form.Group className="mb-3">
                            <Form.Label>RC Copy</Form.Label>
                            {edit ? (
                              <Form.Control
                                type="file"
                                accept=".pdf"
                                name="rc_image"
                                onChange={handlePhoto}
                              />
                            ) : (
                              <>
                                <br />
                                <a
                                  href={`${config.server.url}/${receivedDetails.rc_image}`}
                                >
                                  <FontAwesomeIcon
                                    className="primary-text-color"
                                    icon={faFileDownload}
                                    size="2x"
                                  />
                                </a>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs="4">
                          <Form.Group className="mb-3">
                            <Form.Label>DL Copy</Form.Label>
                            {edit ? (
                              <Form.Control
                                type="file"
                                accept=".pdf"
                                name="dl_image"
                                onChange={handlePhoto}
                              />
                            ) : (
                              <>
                                <br />
                                <a
                                  href={`${config.server.url}/${receivedDetails.dl_image}`}
                                >
                                  <FontAwesomeIcon
                                    className="primary-text-color"
                                    icon={faFileDownload}
                                    size="2x"
                                  />
                                </a>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <p>
                          <b>Grid Assignment</b>
                        </p>
                        <Col xs={10}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Label>
                                State
                                {edit
                                  ? ` - ${receivedDetails.Service_Grid.Service_City.Service_State.state_name}`
                                  : null}
                              </Form.Label>
                              {edit ? (
                                <Form.Select
                                  name="state"
                                  onChange={handleStateChange}
                                >
                                  <option value="">Select a state</option>
                                  {states.map((item, index) => (
                                    <option key={item.id} value={item.id}>
                                      {item.state_name}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <span>
                                  <br />
                                  {
                                    receivedDetails.Service_Grid.Service_City
                                      .Service_State.state_name
                                  }
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>
                                City
                                {edit
                                  ? ` - ${receivedDetails.Service_Grid.Service_City.city_name}`
                                  : null}
                              </Form.Label>
                              {edit ? (
                                <Form.Select
                                  name="city"
                                  onChange={handleCityChange}
                                >
                                  <option value="">Select a city</option>
                                  {cities.map((item, index) => (
                                    <option key={item.id} value={item.id}>
                                      {item.city_name}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <span>
                                  <br />
                                  {
                                    receivedDetails.Service_Grid.Service_City
                                      .city_name
                                  }
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>
                                Grid
                                {edit
                                  ? ` - ${receivedDetails.Service_Grid.grid_name}`
                                  : null}
                              </Form.Label>
                              {edit ? (
                                <Form.Select
                                  name="service_grid_id"
                                  onChange={
                                    type === "enabler"
                                      ? handleGridChange
                                      : handleChange
                                  }
                                >
                                  <option value="">Select a grid</option>
                                  {grids.map((item, index) => (
                                    <option key={item.id} value={item.id}>
                                      {item.grid_name}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <span>
                                  <br />
                                  {receivedDetails.Service_Grid.grid_name}
                                </span>
                              )}
                            </Form.Group>

                            {
                              //[TODO]
                              //create a multiselect dropdown pin; [done]
                              //fetch pins for a given grid and display in multiselect drop pin. [done]
                              //one-many enabler-pin; one-one pin-enabler; else reset. [done]
                              //useFetch->api find enabler_mappings for a given enablerId [done]
                              //set default values for State,City,Grid,Pin [done]
                              //update enabler for a given enablerId on onClick(update) [done]
                              //make {api} update - enabler - mapping via enabler_id in enabler_mappings [done]
                              //update values = create (api) to  delete all tags for that given enabler_id + create records for each tag for a given enabler [done]
                            }
                            {type == "enabler" && (
                              <Row className="mt-3">
                                <Form.Group as={Col}>
                                  <Form.Label>
                                    Pin
                                    {edit
                                      ? ` -  ${selectedStringPins}` //need to change => enablerPinActive
                                      : null}
                                  </Form.Label>
                                  {edit ? (
                                    <Select
                                      //  defaultValue={selectedOption}
                                      onChange={(e) => {
                                        setSelectedOption();
                                        handlePinChange(e);
                                      }}
                                      ref={selectInputRef}
                                      options={pins}
                                      isMulti
                                    />
                                  ) : (
                                    <span>
                                      <br />
                                      {selectedStringPins}
                                    </span>
                                  )}
                                </Form.Group>
                              </Row>
                            )}

                            {type === "enabler" && (
                              <Form.Group as={Col} className="mt-3">
                                <Form.Label>
                                  Master Enabler
                                  {edit
                                    ? ` - ${
                                        receivedDetails.M_Enabler !== null
                                          ? receivedDetails.M_Enabler.full_name
                                          : null
                                      }`
                                    : null}
                                </Form.Label>
                                <br />
                                {edit ? (
                                  <Form.Label className="update-enabler-master-enabler-name mt-3">
                                    {masterEnabler !== null ? (
                                      masterEnabler.full_name
                                    ) : (
                                      <span className="update-enabler-master-enabler-name-empty">
                                        No Master Enabler assigned
                                      </span>
                                    )}
                                  </Form.Label>
                                ) : (
                                  <span>
                                    {receivedDetails.M_Enabler !== null
                                      ? receivedDetails.M_Enabler.full_name
                                      : null}
                                  </span>
                                )}
                              </Form.Group>
                            )}
                          </Row>
                        </Col>
                        <Col
                          className="update-enabler-save-button-container"
                          xs={2}
                        >
                          {edit ? (
                            <>
                              <Button
                                className="medium-button"
                                onClick={handleSubmit}
                              >
                                {" "}
                                {/*onClick create enabler-service_pin_id relation on Enabler_mappings and update enabler */}
                                UPDATE
                              </Button>
                              <Button
                                className="medium-button-danger"
                                onClick={() => {
                                  window.location.reload();
                                }}
                              >
                                CANCEL
                              </Button>
                            </>
                          ) : (
                            <span
                              className="update-enabler-span-button"
                              onClick={() => setEdit(true)}
                            >
                              EDIT
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                </Container>
              ) : (
                <div class="lds-hourglass p-4"></div>
              )
            ) : (
              <h2>The page you are looking for doesn't exist :( </h2>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateEnabler;
