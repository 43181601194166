import axios from "axios";
import config from "../config/config";

export const getMasterEnablers = async (user_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getMasterEnablers`,
      params: {
        user_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getMasterEnabler = async (
  user_id,
  service_grid_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getMasterEnabler`,
      params: {
        user_id,
        service_grid_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const addEnabler = async (enablerData, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/addEnabler`,
      data: enablerData,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 409
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getEnablerDetails = async (
  user_id,
  enabler_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getEnabler`,
      params: {
        user_id,
        enabler_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const updateEnabler = async (enablerData, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/updateEnabler`,
      data: enablerData,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    console.log(error, "error");
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 409
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getEnablers = async (
  user_id,
  role_id,
  offset,
  searchParams,
  orderParams,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getEnablers`,
      params: {
        user_id,
        role_id,
        offset,
        searchParams,
        orderParams,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getEnablersList = async (
  user_id,
  service_grid_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getEnablersList`,
      params: {
        user_id,
        service_grid_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const postEnablerPinMaping = async (
  user_id,
  enabler_id,
  service_pin_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/postEnablerPinMaping`,
      data: {
        user_id,
        enabler_id,
        service_pin_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getEnablerPins = async (user_id, enabler_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getEnablerPins`,
      params: {
        user_id,
        enabler_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const deleteRelationEnablerPin = async (
  user_id,
  enabler_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getDeleteEnablerPins`,
      params: {
        user_id,
        enabler_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const postpropertyEnabler = async (
  user_id,
  service_pin_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/postpropertyEnabler`,
      data: {
        user_id,
        service_pin_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const updatePropertyEnabler = async (
  user_id,
  service_pin_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/updatePropertyEnabler`,
      data: {
        user_id,
        service_pin_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getMasterEnablerJobs = async (
  user_id,
  master_enabler_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getMEnablerJobs`,
      params: {
        user_id,
        master_enabler_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const postGeofenceSchedule = async (
  user_id,
  property_id,
  geofencing_scheduled_at,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/postGeoSchedule`,
      data: {
        user_id,
        property_id,
        geofencing_scheduled_at,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
