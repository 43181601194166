import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./SideBar.css";

import dashboardLogo from "../../assests/home.png";
import kycReqLogo from "../../assests/kycreq.png";
import serviceGridsLogo from "../../assests/servicegrids.png";
// import tutorialsLogo from "../../assests/tutorials.png";
import subscribersLogo from "../../assests/subscribers.png";
import masterEnablersLogo from "../../assests/masterenablers.png";
import enablersLogo from "../../assests/enablers.png";
import { changeActive } from "../../redux/features/sidebar.slice";

const SideBar = () => {
  const isActive = useSelector((state) => state.sidebar.isActive);
  const sidebar = useSelector((state) => state.bar.sidebar);
  const dispatch = useDispatch();

  return (
    <Container
      fluid
      className={sidebar ? `sidebar-container` : `hidden-container`}
    >
      <div className="sidebar-link-container">
        {/* <p className="sidebar-link-container-title">Manage</p> */}
        <Link
          to="/dashboard"
          className={
            isActive === "dashboard" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("dashboard"))}
        >
          <img
            className="sidebar-logo"
            src={dashboardLogo}
            alt="dashboardLogo"
          />
          Dashboard
        </Link>
        <Link
          to="/kyc-requests"
          className={
            isActive === "kyc" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("kyc"))}
        >
          <img className="sidebar-logo" src={kycReqLogo} alt="kycReqLogo" />
          KYC Requests
        </Link>
        <Link
          to="/pending-schedules"
          className={
            isActive === "pending-schedules"
              ? "sidebar-isActive-link"
              : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("pending-schedules"))}
        >
          <img className="sidebar-logo" src={kycReqLogo} alt="kycReqLogo" />
          Pending Schedules
        </Link>
        <Link
          to="/service-grids"
          className={
            isActive === "service" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("service"))}
        >
          <img
            className="sidebar-logo"
            src={serviceGridsLogo}
            alt="serviceGridsLogo"
          />
          Service Grids
        </Link>
        {/* <Link
          to="/tutorials"
          className={
            isActive === "tutorials" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("tutorials"))}
        >
          <img
            className="sidebar-logo"
            src={tutorialsLogo}
            alt="tutorialsLogo"
          />
          Tutorials
        </Link> */}
        <Link
          to="/subscribers"
          className={
            isActive === "subscribers"
              ? "sidebar-isActive-link"
              : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("subscribers"))}
        >
          <img
            className="sidebar-logo"
            src={subscribersLogo}
            alt="subscribersLogo"
          />
          Subscribers
        </Link>

        <Link
          to="/enablers/master"
          className={
            isActive === "master" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("master"))}
        >
          <img
            className="sidebar-logo"
            src={masterEnablersLogo}
            alt="masterEnablersLogo"
          />
          Master Enablers
        </Link>
        <Link
          to="/enablers/enabler"
          className={
            isActive === "enabler" ? "sidebar-isActive-link" : "sidebar-link"
          }
          onClick={() => dispatch(changeActive("enabler"))}
        >
          <img className="sidebar-logo" src={enablersLogo} alt="enablersLogo" />
          Enablers
        </Link>
      </div>
    </Container>
  );
};

export default SideBar;
