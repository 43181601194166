import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

import "./KYCModal.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
// import downloadLogo from "../../assests/download.png";

const KYCModal = (props) => {
  const kycDetails = props.details;

  return (
    <Container className="kyc-modal-container">
      <Row>
        <span className="kyc-modal-title">
          {kycDetails.User.full_name}

          <Link to={`/subscribers/${kycDetails.User.id}`}>
            <Button className="medium-button kyc-modal-profile-button">
              View Profile
            </Button>
          </Link>
        </span>

        <p className="kyc-modal-user-details">
          {kycDetails.User.phone_number}, {kycDetails.User.email} <br />
          {kycDetails.User.address}
        </p>
      </Row>
      <Row className="kyc-modal-docs-info">
        <Col xs="auto" className="kyc-modal-docs-info-details">
          <span className="kyc-modal-docs-info-title">KYC Doc :&nbsp;</span>
          <span className="kyc-modal-docs-info-value">
            {" "}
            {kycDetails.doc_type}
          </span>
          <span className="kyc-modal-docs-info-title2">KYC Doc No :&nbsp;</span>
          <span className="kyc-modal-docs-info-value">
            {" "}
            {kycDetails.doc_number}
          </span>
        </Col>
        <Col xs="auto" className="p-0">
          <a href={`${kycDetails.kyc_doc}`} target="_blank" rel="noreferrer">
            {/* <img
              className="kyc-modal-download-button"
              src={downloadLogo}
              alt="Download Logo"
            /> */}
            <FontAwesomeIcon
              className="kyc-modal-download-button"
              icon={faFileDownload}
            />
          </a>
        </Col>
      </Row>
      <Row className="kyc-modal-selfie-row">
        <Col xs="auto" className="kyc-modal-selfie-col">
          <img
            className="kyc-modal-selfie"
            src={`${kycDetails.photo_without_kyc_doc}`}
            alt="without KYC"
          />
          <br />
          <span className="kyc-modal-selfie-type">Photo Without KYC Doc</span>
        </Col>
        <Col xs="auto" className="kyc-modal-selfie-col">
          <img
            className="kyc-modal-selfie"
            src={`${kycDetails.photo_with_kyc_doc}`}
            alt="with KYC"
          />
          <br />
          <span className="kyc-modal-selfie-type">Photo With KYC Doc</span>
        </Col>
      </Row>
      <Row>
        <span className="kyc-modal-note">
          * Click and hold on photo to enlarge
        </span>
      </Row>
    </Container>
  );
};

export default KYCModal;
