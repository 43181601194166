import axios from "axios";
import config from "../config/config";

export const getGeoCord = async (user_id, property_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getPropertyGeoPoints`,
      params: {
        user_id,
        property_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getDashboardDetails = async (user_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getDashboardDetails`,
      params: {
        user_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getExportData = async (callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getAnalyticsSheet`,
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
    if (response.status === 200) {
      callback(null, response);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const generatePDFReport = async (user_id, report_id, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/mEnablerRoutes/generateSubscriberPdfReport`,
      data: {
        user_id,
        report_id,
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getDashboardAnayltics = async (callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getAnalytics`,
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
