import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./NavBar.css";

import techEyeLogo from "../../assests/techEyeLogo.png";
import { logout } from "../../redux/features/users.slice";
import jwtDecode from "jwt-decode";

const NavBar = () => {
  const token = useSelector((state) => state.user.token);
  const navbar = useSelector((state) => state.bar.navbar);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 - 300000 < Date.now()) {
        alert("session expired , please sigin again");
        dispatch(logout());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      fluid
      className={navbar ? `navbar-container` : `hidden-container`}
    >
      <Link to="/" className={"navbar-title-link-text"}>
        <h1 className="navbar-title">
          <img className="navbar-logo" src={techEyeLogo} alt="techEyeLogo" />
          <span className="navbar-title-1">TECH</span>
          <span className="navbar-title-2">EYE</span>
        </h1>
      </Link>

      {token ? (
        <Link to="/" className="navbar-link-text">
          <Button
            onClick={() => {
              dispatch(logout());
            }}
            className="medium-button"
          >
            Sign Out
          </Button>
        </Link>
      ) : (
        <Link to="/" className="navbar-link-text">
          <Button className="medium-button">Sign In</Button>
        </Link>
      )}
    </Container>
  );
};

export default NavBar;
