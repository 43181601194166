import React from "react";

const NotFound = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: 300 }}>
        404 l Page Not Found
      </h2>
    </>
  );
};

export default NotFound;
