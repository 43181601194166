import React, { useState, useEffect } from "react";
import { Form, Button, Container, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/features/users.slice";
import {
  changeNavBarDisplay,
  changeSideBarDisplay,
} from "../../redux/features/bars.slice";

import "./LogIn.css";

import passwordLock from "../../assests/passwordLock.png";
import mailLogo from "../../assests/mail.png";
import techEyeLogo from "../../assests/techEyeLogo.png";
import { createPassword, login } from "../../modules/user.modules";

const LogIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.info);
  const [user_id, set_user_id] = useState();

  const [show, setShow] = useState(false);

  const [errors, setErrors] = useState({
    code: 0,
    message: "",
  });

  const [reqValues, setReqValues] = useState({
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
    dispatch(changeNavBarDisplay(false));
    dispatch(changeSideBarDisplay(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setReqValues({ ...reqValues, [e.target.name]: e.target.value });
    setErrors({ code: 0, message: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      login(reqValues.email, reqValues.password, (error, user) => {
        if (error) {
          setErrors({ code: error.code, message: error.message });
          console.log(error);
        } else if (user) {
          if (user.data.isTemp === false) {
            dispatch(userLogin(user.data));
            history.push("/dashboard");
          }
          if (user.data.isTemp === true) {
            set_user_id(user.data.user.user_id);
            setShow(true);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreatePasswordSubmit = (e) => {
    e.preventDefault();

    if (reqValues.newPassword !== reqValues.confirmPassword) {
      setErrors({ code: 1, message: "Passwords don't match" });
    } else {
      try {
        createPassword(
          reqValues.email,
          reqValues.newPassword,
          user_id,
          (error, user) => {
            if (error) {
              setErrors({ code: error.code, message: error.message });
              console.log(error);
            } else if (user) {
              alert(
                "Password updated!\nYou can now sigin using your new password."
              );
              window.location.reload(false);
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container fluid className="login-parent-container">
      <h1 className="login-title">
        <img className="logo" src={techEyeLogo} alt="techEyeLogo" />
        <span className="login-title-1">TECH</span>
        <span className="login-title-2">EYE</span>
      </h1>
      <div className="login-container">
        <Form className="login-form" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="login-label">
              <img className="logo" src={mailLogo} alt="mailLogo" />
              Email Id
            </Form.Label>
            <Form.Control
              className="login-input"
              value={reqValues.email}
              type="email"
              name="email"
              onChange={handleChange}
              required
            />
            {errors.code === 404 && (
              <Form.Label className="error-label">{errors.message}</Form.Label>
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="login-label">
              <img className="logo" src={passwordLock} alt="passwordLock" />
              Password
            </Form.Label>
            <Form.Control
              className={
                errors.code === 400 ? "login-input-error" : "login-input"
              }
              value={reqValues.password}
              type="password"
              name="password"
              onChange={handleChange}
              required
            />
            {errors.code === 400 && (
              <Form.Label className="error-label">{errors.message}</Form.Label>
            )}
          </Form.Group>
          <div className="login-bottom-group">
            <Link to="/reset_password" className="login-forgot-password">
              Forgot Password?
            </Link>
            <Button variant="primary" className="large-button" type="submit">
              Sign In
            </Button>
          </div>
        </Form>
      </div>
      <div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          centered
          animation={false}
        >
          <Modal.Header className="login-modal-header">
            <Modal.Title>
              <h1 className="login-modal-title">
                <img className="logo" src={techEyeLogo} alt="techEyeLogo" />
                <span className="login-title-1">TECH</span>
                <span className="login-title-2">EYE</span>
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Create a new Password</h5>
            <Form className="login-form" onSubmit={handleCreatePasswordSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="login-label">
                  <img className="logo" src={passwordLock} alt="passwordLock" />
                  Password
                </Form.Label>
                <Form.Control
                  className="login-input"
                  value={reqValues.newPassword}
                  type="password"
                  name="newPassword"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="login-label">
                  <img className="logo" src={passwordLock} alt="passwordLock" />
                  Confirm Password
                </Form.Label>
                <Form.Control
                  className="login-input"
                  value={reqValues.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  required
                />
                {errors.code === 1 && (
                  <Form.Label className="error-label">
                    {errors.message}
                  </Form.Label>
                )}
              </Form.Group>
              <Button className="large-button" type="submit">
                Create Password
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <p className="login-model-note">
              Once you create a new password, the temporary password will be
              invalid.
            </p>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default LogIn;
