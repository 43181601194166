import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "./ForgotPassword.css";

import mailLogo from "../../assests/mail.png";
// import NavBar from "../../components/NavBar/NavBar";
import { resetPassword } from "../../modules/user.modules";
import {
  changeNavBarDisplay,
  changeSideBarDisplay,
} from "../../redux/features/bars.slice";

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    code: 0,
    message: "",
  });

  const [reqValues, setReqValues] = useState({
    email: "",
  });

  useEffect(() => {
    dispatch(changeNavBarDisplay(true));
    dispatch(changeSideBarDisplay(false));
  });

  const handleChange = (e) => {
    setReqValues({ ...reqValues, [e.target.name]: e.target.value });
    setErrors({ code: 0, message: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      resetPassword(reqValues.email, (error, successMessage) => {
        if (error) {
          setErrors({ code: error.code, message: error.message });
          console.log(errors);
        } else if (successMessage) {
          alert("A temporary password has been sent to your mail id!");
          history.push("/");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="forgot-password-parent-container">
        <div className="forgot-password-container">
          <Form className="forgot-password-form" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="forgot-password-label">
                <img className="logo" src={mailLogo} alt="mailLogo" />
                Email Id
              </Form.Label>
              <Form.Control
                className="forgot-password-input"
                name="email"
                value={reqValues.email}
                onChange={handleChange}
                type="email"
                required
              />
              {errors.code === 404 && (
                <Form.Label className="error-label">
                  {errors.message}
                </Form.Label>
              )}
            </Form.Group>
            <Form.Label className="mb-4">
              A temporary password will be sent to your email.
            </Form.Label>
            <div className="forgot-password-bottom-group">
              <Button variant="primary" className="large-button" type="submit">
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default ForgotPassword;
