import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import "./CustomLabel.css"; // Import the CSS file

const CustomLabel = ({ title, value }) => {
  return (
    <div className="dashboard-label-container">
      <span className="label-title">{title}</span>
      <span className="label-value">{value}</span>
    </div>
  );
};

export default CustomLabel;
