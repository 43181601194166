import axios from "axios";
import config from "../config/config";

export const getKYCRequests = async (
  user_id,
  offset,
  searchParams,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getKYCRequests`,
      params: {
        user_id,
        offset,
        searchParams,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const updateKYCReqStatus = async (
  user_id,
  kyc_req_id,
  rejectionReason,
  status,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/updateKYCRequestStatus`,
      data: {
        user_id,
        kyc_req_id,
        status,
        rejection_reason: rejectionReason,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getSubscriberDetails = async (
  user_id,
  offset,
  searchParams,
  orderParams,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getSubscribers`,
      params: {
        user_id,
        offset,
        searchParams,
        orderParams,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const deleteSubscriberOrEnabler = async (
  user_id,
  sub_enabler_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/deleteUser`,
      data: {
        user_id,
        sub_enabler_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getStates = async (user_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getAllStates`,
      params: {
        user_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (error.response) {
      if (
        error.response.status === 404 ||
        error.response.status === 500 ||
        error.response.status === 401 ||
        error.response.status === 400
      ) {
        if (error.response.data) {
          callback(error.response.data, null);
        }
      }
    }
  }
};

export const getCities = async (user_id, state_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getCities`,
      params: {
        user_id,
        state_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getGrids = async (user_id, city_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getGrids`,
      params: {
        user_id,
        city_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getPins = async (user_id, grid_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getPins`,
      params: {
        user_id,
        grid_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const addGrid = async (user_id, city_id, grid_name, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/addGrid`,
      data: {
        user_id,
        city_id,
        grid_name,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 409
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const addPin = async (
  user_id,
  grid_id,
  area_name,
  pin_code,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/addPin`,
      data: {
        user_id,
        grid_id,
        area_name,
        pin_code,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 409
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//delete pin also should delete all enablers in enabler_mappings with that pin [not done]
export const deletePin = async (user_id, pin_id, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/deletePin`,
      data: {
        user_id,
        pin_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 409
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getSubscriberInfo = async (
  user_id,
  subscriber_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getSubscriber`,
      params: {
        user_id,
        subscriber_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getSubscriberProperties = async (
  user_id,
  subscriber_id,
  offset,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getProperties`,
      params: {
        user_id,
        subscriber_id,
        offset,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const addPropertyEnablers = async (
  user_id,
  updateEnablersParams,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/addPropertyEnablers`,
      data: {
        user_id,
        updateEnablersParams,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//get kyc status
export const getkycStatus = async (user_id, subscriber_id, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/getKycStatusUser/${subscriber_id}`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
      },
    });
    if (response.status == 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//get payment info
export const getPaymentInfo = async (user_id, property_id, token, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/getUserPaymentInfo/${property_id}`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
      },
    });

    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//generate reports
export const postRequestGenerateReport = async (
  user_id,
  property_id,
  scheduled_date,
  scheduled_date_next,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/postReportScheduling`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
        property_id,
        scheduled_date,
        scheduled_date_next,
      },
    });

    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const postReportStatus = async (
  user_id,
  user_property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/postScheduleGeneratorStatus`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
        user_property_id,
      },
    });

    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getPropertyInitialImage = async (
  user_id,
  user_property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/getPropertyInitialImage`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        user_id,
        user_property_id,
      },
    });
    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getHtmlFeed = async (callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/auth/getHtmlFeed?key=privacy_policy`,
    });

    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getExistingPinInfo = async (userId, pinId, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${config.server.url}/admin/getExistingPinInfo?user_id=${userId}&service_pin_id=${pinId}`,
    });
    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getEnablerMasterInfo = async (
  userId,
  property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${config.server.url}/admin/getEnablerMasterInfo?user_id=${userId}&property_id=${property_id}`,
    });
    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getPdfReports = async (userId, property_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${config.server.url}/admin/getPdfReports?user_id=${userId}&property_id=${property_id}`,
    });
    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getPropertyFeed = async (userId, property_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${config.server.url}/admin/getPropertyFeed?user_id=${userId}&property_id=${property_id}`,
    });
    if (response.status == 200) {
      callback(null, response.data);
      return;
    }
  } catch (e) {
    callback(e, null);
  }
};

export const getScheduledReports = async (
  user_id,
  property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getScheduledReports`,
      params: {
        user_id,
        property_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const updateReportScheduledDate = async (
  user_id,
  property_id,
  report_id,
  scheduled_date,
  new_scheduled_date,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/updateReportScheduledDate`,
      data: {
        user_id,
        property_id,
        report_id,
        scheduled_date,
        new_scheduled_date,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getPendingSchedules = async (
  user_id,
  offset,
  type,
  name,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/admin/getPendingSchedules`,
      params: {
        user_id,
        offset,
        type,
        name,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const rejectNOCStatus = async (
  user_id,
  property_id,
  video_noc_rejection_reason,
  video_noc_status,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/rejectVideoNOC`,
      data: {
        user_id,
        property_id,
        video_noc_status,
        video_noc_rejection_reason,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const discontinueService = async (
  user_id,
  property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/discontinueService`,
      data: {
        user_id,
        property_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const getPropertyDocs = async (
  user_id,
  property_id,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/user/getPropertyDocs`,
      params: {
        user_id,
        property_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const rejectPropertyDoc = async (
  user_id,
  property_id,
  property_doc_id,
  property_doc_status,
  property_doc_rejection_reason,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/admin/rejectPropertyDoc`,
      data: {
        user_id,
        property_id,
        property_doc_id,
        property_doc_status,
        property_doc_rejection_reason,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
