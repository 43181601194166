import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Subscriber.css";
import L from "leaflet";
import locationLogo from "../../assests/location.png";

//create a marker Icon
const markerIcon = new L.Icon({
  iconUrl: locationLogo,
  iconSize: [35, 45],
});

function LeafletMap({ props }) {
  const [polygonVal, setpolygon] = useState([]);
  const { BaseLayer } = LayersControl;

  useEffect(() => {
    setpolygon([]);
    props.map((val) => {
      setpolygon((codes) => {
        return [...codes, [val.latitude, val.longitude]];
      });
    });
  }, [props.length > 0]);

  const center = polygonVal.length > 1 ? polygonVal[0] : null;

  if (center) {
    return (
      <div className="border-map">
        <MapContainer
          center={polygonVal[polygonVal.length - 2]}
          zoom={30}
          style={{ width: "95%", height: "450px", marginBottom: "30px" }}
        >
          <LayersControl>
            <BaseLayer checked name="Satellite View">
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={["mt1", "mt2", "mt3"]}
              />
            </BaseLayer>
            <BaseLayer name="Street View">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>

          {polygonVal.map((val) => {
            return (
              <Marker position={val} icon={markerIcon}>
                <Popup>GeoPoint</Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LeafletMap;
