import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isActive: "dashboard",
  },
  reducers: {
    changeActive: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const { changeActive } = sidebarSlice.actions;

export default sidebarSlice.reducer;
