import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";

import "./ServiceGrids.css";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";

import {
  addGrid,
  addPin,
  deletePin,
  getCities,
  getGrids,
  getPins,
  getStates,
} from "../../modules/subscriber.modules";
import { Link } from "react-router-dom";

const ServiceGrids = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [grids, setGrids] = useState([]);
  const [pins, setPins] = useState([]);
  const [pinId, setPinId] = useState([]);
  const [currentGrid,setCurrentGrid] = useState([])
  const [modalStates, setModalStates] = useState([]);
  const [modalCities, setModalCities] = useState([]);
  const [addGridParams, setAddGridParams] = useState({
    city_id: "",
    grid_name: "",
  });
  const [addPinParams, setAddPinParams] = useState({
    area_name: "",
    pin_code: "",
    grid_id: "",
  });

  useEffect(() => {
    dispatch(changeActive("service"));

    try {
      getStates(user.user_id, token, (error, states) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (states) {
          setStates(states.data);
          setModalStates(states.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStateChange = (e) => {
    if (e.target.value !== "") {
      try {
        getCities(user.user_id, e.target.value, token, (error, cities) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (cities) {
            setCities([]);
            setCities(cities.data);
            setGrids([]);
            setPins([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCities([]);
      setGrids([]);
      setPins([]);
    }
  };

  const handleCityChange = (e) => {
    if (e.target.value !== "") {
      try {
        getGrids(user.user_id, e.target.value, token, (error, grids) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (grids) {
            setGrids([]);
            setGrids(grids.data);
            setPins([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
      setPins([]);
    }
  };

  const handleGridChange = (e) => {
    if (e.target.value !== "") {
      setCurrentGrid(e.target.value)
      setAddPinParams({ ...addPinParams, grid_id: e.target.value });
      try {
        getPins(user.user_id, e.target.value, token, (error, pins) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (pins) {
            setPins(pins.data);
            setAddPinParams({ ...addPinParams, area_name: "", pin_code: "" });
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setPins([]);
    }
  };

  const handleModalStateChange = (e) => {
    if (e.target.value !== "") {
      try {
        getCities(user.user_id, e.target.value, token, (error, cities) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (cities) {
            setModalCities([]);
            setModalCities(cities.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setModalCities([]);
    }
  };

  const handleChange = (e) => {
    setAddGridParams({ ...addGridParams, [e.target.name]: e.target.value });
    setAddPinParams({ ...addPinParams, [e.target.name]: e.target.value,'grid_id':currentGrid });
  };

  const handleAddGrid = () => {
    try {
      if (addGridParams.city_id === "" || addGridParams.grid_name === "") {
        alert("please select a city and enter grid name");
        return;
      }

      addGrid(
        user.user_id,
        addGridParams.city_id,
        addGridParams.grid_name,
        token,
        (error, grid) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (grid) {
            alert("Grid Added !!!");
            setAddGridParams({ city_id: "", grid_name: "" });
            setModalCities([]);
            setShow(false);
            window.location.reload();
          }
        }
      );
    } catch (error) {
      alert(error)
      console.log(error);
    }
  };

  const fetchNewPins = () => {
    try {
      getPins(user.user_id, addPinParams.grid_id, token, (error, pins) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (pins) {
          setPins(pins.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddPin = () => {
    try {
      if (
        addPinParams.area_name === "" ||
        addPinParams.pin_code === "" ||
        addPinParams.grid_id === ""
      ) {
        alert("Please select a grid and fill area name and pin code");
        return;
      }

      addPin(
        user.user_id,
        addPinParams.grid_id,
        addPinParams.area_name,
        addPinParams.pin_code,
        token,
        (error, pin) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (pin) {
            alert("Pin Added !!!");
            setAddPinParams({ ...addPinParams, area_name: "", pin_code: "" });
            fetchNewPins();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = (paramName) => {
    if (paramName === "area_name") {
      setAddPinParams({ ...addPinParams, area_name: "" });
    }

    if (paramName === "pin_code") {
      setAddPinParams({ ...addPinParams, pin_code: "" });
    }
  };

  const handlePinDeleteClick = (pin_id) => {
    setPinId(pin_id);
    setShowDeleteModal(true);
  };

  const handleDeletePin = () => {
    try {
      deletePin(user.user_id, pinId, token, (error, deleteDone) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (deleteDone) {
          if (deleteDone.data === 1) {
            alert("Pin Deleted!!!");
          } else {
            alert("Something went wrong, please try again");
          }
          setShowDeleteModal(false);
          fetchNewPins();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            <Container className="main-page-content-container">
              <Row>
                <p className="service-grid-title">SERVICE GRID CONFIGURATION</p>
                <Form>
                  <Row>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label className=" service-grid-label">
                        State
                      </Form.Label>
                      <Form.Select name="state" onChange={handleStateChange}>
                        <option value="">Select a state</option>
                        {states.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label className="service-grid-label">
                        City
                      </Form.Label>
                      <Form.Select name="city" onChange={handleCityChange}>
                        <option value="">Select a city</option>
                        {cities.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label className="service-grid-label">
                        Grid
                      </Form.Label>
                      <Form.Select name="grids" onChange={handleGridChange}>
                        <option value="">Select a grid</option>
                        {grids.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.grid_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Col xs={2} className="service-grid-button-col">
                      <Button
                        className="medium-button"
                        onClick={() => setShow(true)}
                      >
                        Add New Grid
                      </Button>
                    </Col>
                    {/* <Col className="service-grid-resize-col" xs={1}>
                      <span
                        className="service-grid-resize"
                        onClick={() => setShow(true)}
                      >
                        Add New Grid
                      </span>
                    </Col> */}
                  </Row>
                </Form>
              </Row>
              <Row className="mt-4">
                <p className="service-grid-label">Covered Area</p>
                <Form>
                  <Row>
                    <Form.Group as={Col} xs={3}>
                      <InputGroup>
                        <Form.Control
                          name="area_name"
                          placeholder="Area Name"
                          value={addPinParams.area_name}
                          type="text"
                          onChange={handleChange}
                        />
                        <InputGroup.Text
                          className="service-grid-clear-button"
                          onClick={() => handleReset("area_name")}
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={3}>
                      <InputGroup>
                        <Form.Control
                          name="pin_code"
                          placeholder="Pin Code"
                          value={addPinParams.pin_code}
                          type="number"
                          onChange={handleChange}
                        />
                        <InputGroup.Text
                          className="service-grid-clear-button"
                          onClick={() => handleReset("pin_code")}
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Col xs={2} className="service-grid-button-col">
                      <Button className="medium-button" onClick={handleAddPin}>
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row className="mt-3">
                <Col xs={8}>
                  <InfiniteScroll
                    dataLength={pins.length}
                    hasMore={false}
                    height={250}
                    endMessage={
                      pins.length !== 0 ? (
                        <p style={{ textAlign: "center" }}>
                          <b>End of results.</b>
                        </p>
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <b>No Pins available in this Grid</b>
                        </p>
                      )
                    }
                  >
                    {pins.map((item) => (
                      <Row key={item.id} className="service-grid-infinity-div">
                        <Col xs={6}>{item.area_name}</Col>
                        <Col xs={5}>{item.pin_code}</Col>
                        <Col xs={1}>
                          <FontAwesomeIcon
                            className="primary-text-color service-grid-close-button"
                            icon={faTimesCircle}
                            onClick={() => handlePinDeleteClick(item.id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </InfiniteScroll>
                </Col>
              </Row>
              <Row>
                <div className="service-grid-enablers-links-container">
                  <Link
                    className="service-grid-enablers-links"
                    to={`/enablers/enabler`}
                  >
                    <span className="service-grid-enablers-span1">
                      <FontAwesomeIcon
                        className="service-grid-logo"
                        icon={faUsers}
                      />
                      Enablers
                    </span>
                  </Link>
                  <Link
                    className="service-grid-enablers-links"
                    to={`/enablers/master`}
                  >
                    <span>
                      <FontAwesomeIcon
                        className="service-grid-logo"
                        icon={faUser}
                      />
                      Master Enablers
                    </span>
                  </Link>
                </div>
              </Row>
            </Container>
            <div>
              <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header>
                  <Modal.Title className="service-grid-modal-header">
                    Add a new Grid
                  </Modal.Title>
                  <FontAwesomeIcon
                    className="service-grid-close-logo"
                    icon={faTimesCircle}
                    onClick={() => setShow(false)}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row>
                      <Form.Group as={Col} xs={6}>
                        <Form.Label className="primary-text-color">
                          State
                        </Form.Label>
                        <Form.Select
                          name="state"
                          onChange={handleModalStateChange}
                        >
                          <option>Select a state</option>
                          {modalStates.map((item, index) => (
                            <option key={item.id} value={item.id}>
                              {item.state_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} xs={6}>
                        <Form.Label className="primary-text-color">
                          City
                        </Form.Label>
                        <Form.Select name="city_id" onChange={handleChange}>
                          <option value="">Select a city</option>
                          {modalCities.map((item, index) => (
                            <option key={item.id} value={item.id}>
                              {item.city_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} xs={6} className="mt-3">
                        <Form.Label className="primary-text-color">
                          Name of the new grid
                        </Form.Label>
                        <Form.Control
                          name="grid_name"
                          value={addGridParams.grid_name}
                          type="text"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Row>
                    <div>
                      <Button
                        onClick={handleAddGrid}
                        className="medium-button service-grid-save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
            <div>
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
              >
                <Modal.Body>
                  Are you sure want to delete ?
                  <div className="service-grid-buttons-row">
                    <Button
                      onClick={() => setShowDeleteModal(false)}
                      className="medium-button service-grid-button"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleDeletePin}
                      className="medium-button-danger service-grid-button"
                    >
                      Delete
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ServiceGrids;
