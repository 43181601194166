import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { faTrash, faEye, faUsers } from "@fortawesome/free-solid-svg-icons";

import "./Enablers.css";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";

// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import {
  deleteSubscriberOrEnabler,
  getCities,
  getGrids,
  getStates,
} from "../../modules/subscriber.modules";
import { changeActive } from "../../redux/features/sidebar.slice";
import { getEnablers } from "../../modules/enablers.module";

const Enablers = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const { type } = useParams();

  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(false);
  const [searching, setSearching] = useState(false);
  const [key, setKey] = useState(0);
  const [enablers, setEnablers] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [enabId, setEnabId] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [grids, setGrids] = useState([]);
  const [gridEnablerParams, setGridEnablerParams] = useState({
    name: "",
    state: "",
    city: "",
    grid: "",
    master_enabler_id: "",
  });
  const [counts, setCounts] = useState({
    activeCount: 0,
    totalCount: 0,
  });
  const [searchParams, setSearchParams] = useState({
    name: "",
    state: "",
    city: "",
    grid: "",
    master_enabler_id: "",
  });
  const [masterSearchParams, setMasterSearchParams] = useState({
    name: "",
    state: "",
    city: "",
    grid: "",
    master_enabler_id: "",
  });
  const [orderParams, setOrderParams] = useState({
    by: "createdAt",
    type: "DESC",
  });

  useEffect(() => {
    dispatch(changeActive(type === "enabler" ? "enabler" : "master"));
    setOffset(10);
    setHasMore(true);
    setStates([]);
    setCities([]);
    setGrids([]);

    // emptying the other page search state
    if (type === "master") {
      setSearchParams({
        name: "",
        state: "",
        city: "",
        grid: "",
        master_enabler_id: "",
      });
      setSearching(false);
    } else {
      setMasterSearchParams({
        name: "",
        state: "",
        city: "",
        grid: "",
        master_enabler_id: "",
      });
    }
    try {
      getEnablers(
        user.user_id,
        type === "master" ? 2 : 3,
        0,
        type === "master"
          ? masterSearchParams
          : searching
          ? gridEnablerParams
          : searchParams,
        orderParams,
        token,
        (error, enablersList) => {
          if (error) {
            console.log(error);
            alert(error.code, " - ", error.message);
          } else if (enablersList) {
            setEnablers(enablersList.data.enablersDetails.rows);
            setTotal(enablersList.data.enablersDetails.count);
            setCounts({
              activeCount: enablersList.data.activeCount,
              totalCount: enablersList.data.totalCount,
            });
            if (
              enablersList.data.enablersDetails.count === 0 ||
              enablersList.data.enablersDetails.count <= 10
            ) {
              setHasMore(false);
              // setSearching(false);
            }
          }
        }
      );
      setKey(key + 1);
    } catch (error) {
      console.log(error);
    }

    try {
      getStates(user.user_id, token, (error, states) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (states) {
          setStates(states.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, orderParams]);

  const handleStateChange = (e) => {
    type === "master"
      ? setMasterSearchParams({
          ...masterSearchParams,
          [e.target.name]: e.target.value,
        })
      : setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    if (e.target.value !== "") {
      try {
        getCities(user.user_id, e.target.value, token, (error, cities) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (cities) {
            setCities([]);
            setCities(cities.data);
            setGrids([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCities([]);
      setGrids([]);
    }
  };

  const handleCityChange = (e) => {
    type === "master"
      ? setMasterSearchParams({
          ...masterSearchParams,
          [e.target.name]: e.target.value,
        })
      : setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    if (e.target.value !== "") {
      try {
        getGrids(user.user_id, e.target.value, token, (error, grids) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (grids) {
            setGrids([]);
            setGrids(grids.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setGrids([]);
    }
  };

  const handleChange = (e) => {
    type === "master"
      ? setMasterSearchParams({
          ...masterSearchParams,
          [e.target.name]: e.target.value,
        })
      : setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleNameReset = () => {
    type === "master"
      ? setMasterSearchParams({
          ...masterSearchParams,
          name: "",
        })
      : setSearchParams({ ...searchParams, name: "" });
  };

  const renderTooltip = (address) => (
    <Tooltip id="button-tooltip">{address}</Tooltip>
  );

  const handleSearch = () => {
    setOffset(10);
    setHasMore(true);
    setSearching(false);

    try {
      getEnablers(
        user.user_id,
        type === "master" ? 2 : 3,
        0,
        type === "master" ? masterSearchParams : searchParams,
        orderParams,
        token,
        (error, enablersList) => {
          if (error) {
            console.log(error);
            alert(error.code, " - ", error.message);
          } else if (enablersList) {
            setEnablers(enablersList.data.enablersDetails.rows);
            setTotal(enablersList.data.enablersDetails.count);
            setCounts({
              activeCount: enablersList.data.activeCount,
              totalCount: enablersList.data.totalCount,
            });
            if (
              enablersList.data.enablersDetails.count === 0 ||
              enablersList.data.enablersDetails.count <= 10
            ) {
              setHasMore(false);
            }
          }
        }
      );

      setKey(key + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    if (enablers.length >= total) {
      setHasMore(false);
      return;
    }

    console.log("I ran", offset);

    try {
      getEnablers(
        user.user_id,
        type === "master" ? 2 : 3,
        offset,
        type === "master"
          ? masterSearchParams
          : searching
          ? gridEnablerParams
          : searchParams,
        orderParams,
        token,
        (error, enablersList) => {
          if (error) {
            console.log(error);
            alert(error.code, " - ", error.message);
          } else if (enablersList) {
            setEnablers([
              ...enablers,
              ...enablersList.data.enablersDetails.rows,
            ]);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }

    setOffset(offset + 10);
  };

  const handleDeleteClick = (sub_id) => {
    setEnabId(sub_id);
    setShow(true);
  };

  const handleDeleteSubscriber = () => {
    try {
      deleteSubscriberOrEnabler(
        user.user_id,
        enabId,
        token,
        (error, deleteDone) => {
          if (error) {
            console.log(error);
            alert(error.code, " - ", error.message);
          } else if (deleteDone) {
            alert(
              `${
                type === "master" ? "master enabler" : "enabler"
              } Status set to Deleted!!!`
            );
            setShow(false);
            handleSearch();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowEnablers = (master_enabler_id) => {
    setGridEnablerParams({
      ...gridEnablerParams,
      master_enabler_id: master_enabler_id,
    });
    setSearching(true);
  };

  const handleOrderChange = (by, type) => {
    setOrderParams({ by: by, type: type });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            {type === "master" || type === "enabler" ? (
              <>
                <Container fluid className="main-page-content-container">
                  <Row>
                    <Form>
                      <Row>
                        {/* <Col xs="auto" className="enablers-search-icon">
                        <FontAwesomeIcon
                          style={{
                            color: "#2a4365",
                          }}
                          icon={faSearch}
                          size="2x"
                        />
                      </Col> */}
                        <Form.Group as={Col} xs={3}>
                          <Form.Label className="enablers-label">
                            Search By
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="name"
                              value={
                                type === "master"
                                  ? masterSearchParams.name
                                  : searchParams.name
                              }
                              onChange={handleChange}
                              placeholder={`Name, Email, Phone Number`}
                            />
                            <InputGroup.Text
                              onClick={handleNameReset}
                              className="enablers-clear-button"
                            >
                              x
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} xs={2}>
                          <Form.Label className="enablers-label">
                            State
                          </Form.Label>
                          <Form.Select
                            name="state"
                            onChange={handleStateChange}
                          >
                            <option value="">Select a state</option>
                            {states.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.state_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} xs={2}>
                          <Form.Label className="enablers-label">
                            City
                          </Form.Label>
                          <Form.Select name="city" onChange={handleCityChange}>
                            <option value="">Select a city</option>
                            {cities.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.city_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        {type === "enabler" ? (
                          <Form.Group as={Col} xs={2}>
                            <Form.Label className="enablers-label">
                              Grid
                            </Form.Label>
                            <Form.Select name="grid" onChange={handleChange}>
                              <option value="">Select a grid</option>
                              {grids.map((item, index) => (
                                <option key={item.id} value={item.id}>
                                  {item.grid_name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        ) : null}
                        <Col
                          xs={2}
                          className="enablers-search-button-container"
                        >
                          <Button
                            className="enablers-search-button"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                          <Link to={`/onboard/${type}`}>
                            <Button
                              className="enablers-search-button"
                              onClick={handleSearch}
                            >
                              Onboard
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                  <Row className="enablers-table">
                    <InfiniteScroll
                      dataLength={enablers.length}
                      key={key}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<div class="lds-hourglass"></div>}
                      height={450}
                      scrollThreshold={0.99}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>End of results.</b>
                        </p>
                      }
                    >
                      <Table striped className="table">
                        <thead className="table-header">
                          <tr>
                            <th></th>
                            <th>
                              <span className="table-header-box">
                                <span>Name</span>

                                <span className="table-header-arrows-box">
                                  <span
                                    onClick={() =>
                                      handleOrderChange("full_name", "ASC")
                                    }
                                    className="table-header-arrows"
                                  >
                                    ▲
                                  </span>
                                  <span
                                    onClick={() =>
                                      handleOrderChange("full_name", "DESC")
                                    }
                                    className="table-header-arrows"
                                  >
                                    ▼
                                  </span>
                                </span>
                              </span>
                            </th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>
                              <span className="table-header-box">
                                <span>Joined On</span>

                                <span className="table-header-arrows-box">
                                  <span
                                    onClick={() =>
                                      handleOrderChange("createdAt", "ASC")
                                    }
                                    className="table-header-arrows"
                                  >
                                    ▲
                                  </span>
                                  <span
                                    onClick={() =>
                                      handleOrderChange("createdAt", "DESC")
                                    }
                                    className="table-header-arrows"
                                  >
                                    ▼
                                  </span>
                                </span>
                              </span>
                            </th>
                            {type === "enabler" ? (
                              <th>Master Enabler</th>
                            ) : null}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enablers.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                <span
                                  className={
                                    item.status === "Active"
                                      ? "status-active-indicator"
                                      : item.status === "Inactive"
                                      ? "status-inactive-indicator"
                                      : "status-deleted-indicator"
                                  }
                                ></span>
                              </td>
                              <td>{item.full_name}</td>
                              <td>{item.phone_number}</td>
                              <td>{item.email}</td>
                              <td>
                                {item.address === null ||
                                item.address.length <= 20 ? (
                                  item.address
                                ) : (
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip(item.address)}
                                  >
                                    <span>{`${item.address.substring(
                                      0,
                                      20
                                    )}...`}</span>
                                  </OverlayTrigger>
                                )}
                              </td>
                              <td>{formatDate(item.createdAt)}</td>
                              {type === "enabler" ? (
                                <td>
                                  {item.M_Enabler !== null &&
                                    item.M_Enabler.full_name}
                                </td>
                              ) : null}
                              <td>
                                {type === "master" && (
                                  <Link
                                    to="/enablers/enabler"
                                    onClick={() => handleShowEnablers(item.id)}
                                  >
                                    <FontAwesomeIcon
                                      className="subscribers-table-actions"
                                      icon={faUsers}
                                    />
                                  </Link>
                                )}
                                &nbsp;&nbsp;
                                <Link
                                  to={
                                    type === "master"
                                      ? `/details/master/${item.id}`
                                      : `/details/enabler/${item.id}`
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="enablers-table-actions"
                                    icon={faEye}
                                  />
                                </Link>
                                &nbsp;&nbsp;
                                {item.status !== "Deleted" && (
                                  <FontAwesomeIcon
                                    className="enablers-table-actions"
                                    onClick={() => handleDeleteClick(item.id)}
                                    icon={faTrash}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </InfiniteScroll>

                    <div className="enablers-count-box">
                      <span>
                        Showing <b>{enablers.length}</b> results out of{" "}
                        <b>{total}</b>
                      </span>
                      <span>
                        <span className="m-2">
                          <span className="status-active-indicator"></span>{" "}
                          Active
                        </span>
                        <span className="m-2">
                          <span className="status-inactive-indicator "></span>{" "}
                          Inactive
                        </span>
                        <span className="m-2">
                          <span className="status-deleted-indicator"></span>{" "}
                          Deleted
                        </span>
                      </span>
                      <span className="subscribers-count">
                        Total Active{" "}
                        {type === "enabler" ? "Enablers" : "Master Enablers"} :{" "}
                        <b>{counts.activeCount}</b> / {counts.totalCount}
                      </span>
                    </div>
                  </Row>
                </Container>
                <div>
                  <Modal show={show} centered onHide={() => setShow(false)}>
                    <Modal.Body className="p-3">
                      This will set user's status to deleted. Are you sure ?
                      <Container className="kyc-modal-buttons-row">
                        <Button
                          className="medium-button kyc-modal-button"
                          onClick={() => setShow(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="medium-button-danger kyc-modal-button"
                          onClick={handleDeleteSubscriber}
                        >
                          Delete
                        </Button>
                      </Container>
                    </Modal.Body>
                  </Modal>
                </div>
              </>
            ) : (
              <h2>The page you are looking for doesn't exist :( </h2>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Enablers;
