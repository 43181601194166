import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

import "./Subscribers.css";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";

// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import {
  deleteSubscriberOrEnabler,
  getSubscriberDetails,
} from "../../modules/subscriber.modules";
import { changeActive } from "../../redux/features/sidebar.slice";

const Subscribers = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(0);
  const [subscribers, setSubscribers] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [subId, setSubId] = useState();
  const [counts, setCounts] = useState({
    activeCount: 0,
    totalCount: 0,
  });
  const [searchParams, setSearchParams] = useState({
    name: "",
    status: "Active",
  });
  const [orderParams, setOrderParams] = useState({
    by: "createdAt",
    type: "DESC",
  });

  useEffect(() => {
    dispatch(changeActive("subscribers"));
    setHasMore(true);
    setOffset(10);
    try {
      getSubscriberDetails(
        user.user_id,
        0,
        searchParams,
        orderParams,
        token,
        (error, subscribersDetails) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (subscribersDetails) {
            setSubscribers(subscribersDetails.data.subscribersDetails.rows);
            setTotal(subscribersDetails.data.subscribersDetails.count);
            setCounts({
              activeCount: subscribersDetails.data.activeCount,
              totalCount: subscribersDetails.data.totalCount,
            });
            if (
              subscribersDetails.data.subscribersDetails.count === 0 ||
              subscribersDetails.data.subscribersDetails.count <= 10
            ) {
              setHasMore(false);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParams]);

  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleNameReset = () => {
    setSearchParams({ ...searchParams, name: "" });
  };

  const renderTooltip = (address) => (
    <Tooltip id="button-tooltip">{address}</Tooltip>
  );

  const handleSearch = () => {
    setOffset(10);
    setHasMore(true);

    try {
      getSubscriberDetails(
        user.user_id,
        0,
        searchParams,
        orderParams,
        token,
        (error, subscribersDetails) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (subscribersDetails) {
            setSubscribers(subscribersDetails.data.subscribersDetails.rows);
            setTotal(subscribersDetails.data.subscribersDetails.count);
            setCounts({
              activeCount: subscribersDetails.data.activeCount,
              totalCount: subscribersDetails.data.totalCount,
            });
            if (
              subscribersDetails.data.subscribersDetails.count === 0 ||
              subscribersDetails.data.subscribersDetails.count <= 10
            ) {
              setHasMore(false);
            }
          }
        }
      );

      setKey(key + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    if (subscribers.length >= total) {
      setHasMore(false);
      return;
    }

    console.log("I ran", offset);
    try {
      getSubscriberDetails(
        user.user_id,
        offset,
        searchParams,
        orderParams,
        token,
        (error, subscribersDetails) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (subscribersDetails) {
            setSubscribers([
              ...subscribers,
              ...subscribersDetails.data.subscribersDetails.rows,
            ]);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }

    setOffset(offset + 10);
  };

  const handleDeleteClick = (sub_id) => {
    setSubId(sub_id);
    setShow(true);
  };

  const handleDeleteSubscriber = () => {
    try {
      deleteSubscriberOrEnabler(
        user.user_id,
        subId,
        token,
        (error, deleteDone) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (deleteDone) {
            alert("Subscriber Status set to Deleted!!!");
            setShow(false);
            handleSearch();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderChange = (by, type) => {
    setOrderParams({ by: by, type: type });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            <Container fluid className="main-page-content-container">
              <Row>
                <Form>
                  <Row>
                    {/* <Col xs="auto" className="subscribers-search-icon">
                      <FontAwesomeIcon
                        style={{
                          color: "#2a4365",
                        }}
                        icon={faSearch}
                        size="2x"
                      />
                    </Col> */}
                    <Form.Group as={Col} xs={6}>
                      <Form.Label className="subscribers-label">
                        Search By
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="name"
                          value={searchParams.name}
                          onChange={handleChange}
                          placeholder={`Name, Email, Phone Number`}
                        />
                        <InputGroup.Text
                          onClick={handleNameReset}
                          className="subscribers-clear-button"
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label className="subscribers-label">
                        Status
                      </Form.Label>
                      <Form.Select
                        name="status"
                        onChange={handleChange}
                        value={searchParams.status}
                        defaultValue="Active"
                      >
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Deleted">Deleted</option>
                      </Form.Select>
                    </Form.Group>
                    <Col xs={1} className="subscribers-search-button-container">
                      <Button
                        className="subscribers-search-button"
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row className="subscribers-table">
                <InfiniteScroll
                  dataLength={subscribers.length}
                  key={key}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div class="lds-hourglass"></div>}
                  height={450}
                  scrollThreshold={0.99}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>End of results.</b>
                    </p>
                  }
                >
                  <Table striped className="table">
                    <thead className="table-header">
                      <tr>
                        <th></th>
                        <th>
                          <span className="table-header-box">
                            <span>Name</span>

                            <span className="table-header-arrows-box">
                              <span
                                onClick={() =>
                                  handleOrderChange("full_name", "ASC")
                                }
                                className="table-header-arrows"
                              >
                                ▲
                              </span>
                              <span
                                onClick={() =>
                                  handleOrderChange("full_name", "DESC")
                                }
                                className="table-header-arrows"
                              >
                                ▼
                              </span>
                            </span>
                          </span>
                        </th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>
                          <span className="table-header-box">
                            <span>Joined On</span>

                            <span className="table-header-arrows-box">
                              <span
                                onClick={() =>
                                  handleOrderChange("createdAt", "ASC")
                                }
                                className="table-header-arrows"
                              >
                                ▲
                              </span>
                              <span
                                onClick={() =>
                                  handleOrderChange("createdAt", "DESC")
                                }
                                className="table-header-arrows"
                              >
                                ▼
                              </span>
                            </span>
                          </span>
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscribers.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <span
                              className={
                                item.status === "Active"
                                  ? "status-active-indicator"
                                  : item.status === "Inactive"
                                  ? "status-inactive-indicator"
                                  : "status-deleted-indicator"
                              }
                            ></span>
                          </td>
                          <td>{item.full_name}</td>
                          <td>{item.phone_number}</td>
                          <td>{item.email}</td>
                          <td>
                            {item.address === null ||
                            item.address.length <= 20 ? (
                              item.address
                            ) : (
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(item.address)}
                              >
                                <span>{`${item.address.substring(
                                  0,
                                  20
                                )}...`}</span>
                              </OverlayTrigger>
                            )}
                          </td>
                          <td>{formatDate(item.createdAt)}</td>
                          <td>
                            <Link to={`/subscribers/${item.id}`}>
                              <FontAwesomeIcon
                                className="subscribers-table-actions"
                                icon={faEye}
                              />
                            </Link>
                            &emsp;
                            {/* {item.status !== "Deleted" && (
                              <FontAwesomeIcon
                                className="subscribers-table-actions"
                                onClick={() => handleDeleteClick(item.id)}
                                icon={faTrash}
                              />
                            )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </InfiniteScroll>

                <div className="subscribers-count-box">
                  <span>
                    Showing <b>{subscribers.length}</b> results out of{" "}
                    <b>{total}</b>
                  </span>
                  <span>
                    <span className="m-2">
                      <span className="status-active-indicator"></span> Active
                    </span>
                    <span className="m-2">
                      <span className="status-inactive-indicator "></span>{" "}
                      Inactive
                    </span>
                    <span className="m-2">
                      <span className="status-deleted-indicator"></span> Deleted
                    </span>
                  </span>
                  <span className="subscribers-count">
                    Total Active Subscribers: <b>{counts.activeCount}</b> /{" "}
                    {counts.totalCount}
                  </span>
                </div>
              </Row>
            </Container>
            <div>
              <Modal show={show} centered onHide={() => setShow(false)}>
                <Modal.Body className="p-3">
                  This will set user's status to deleted. Are you sure ?
                  <Container className="kyc-modal-buttons-row">
                    <Button
                      className="medium-button kyc-modal-button"
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="medium-button-danger kyc-modal-button"
                      onClick={handleDeleteSubscriber}
                    >
                      Delete
                    </Button>
                  </Container>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Subscribers;
