import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";

import PrivateRoute from "./PrivateRoute";

import LogIn from "./pages/LogIn/LogIn";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import DashBoard from "./pages/DashBoard/DashBoard";
import KYCRequests from "./pages/KYCRequests/KYCRequests";
import ServiceGrids from "./pages/ServiceGrids/ServiceGrids";
import Subscribers from "./pages/Subscribers/Subscribers";
import Subscriber from "./pages/Subscriber/Subscriber";
import Enablers from "./pages/Enablers/Enablers";
import Tutorials from "./pages/Tutorials/Tutorials";
import Onboard from "./pages/Onboard/Onboard";
import NotFound from "./pages/NotFound/NotFound";
import UpdateEnabler from "./pages/UpdateEnabler/UpdateEnabler";
import PendingSchedules from "./pages/Pending/PendingSchedules";
import SideBar from "./components/SideBar/SideBar";
import NavBar from "./components/NavBar/NavBar";

function App() {
  return (
    <div>
      <Container className="parent-container" fluid>
        <BrowserRouter>
          <NavBar />
          <SideBar />
          <Switch>
            <Route exact path="/" component={LogIn} />
            <Route exact path="/reset_password" component={ForgotPassword} />
            <PrivateRoute exact path="/dashboard" component={DashBoard} />
            <PrivateRoute exact path="/kyc-requests" component={KYCRequests} />
            <PrivateRoute
              exact
              path="/pending-schedules"
              component={PendingSchedules}
            />
            <PrivateRoute
              exact
              path="/service-grids"
              component={ServiceGrids}
            />
            <PrivateRoute exact path="/subscribers" component={Subscribers} />
            <PrivateRoute
              exact
              path="/subscribers/:id"
              component={Subscriber}
            />
            <PrivateRoute exact path="/enablers/:type" component={Enablers} />
            <PrivateRoute exact path="/tutorials" component={Tutorials} />
            <PrivateRoute exact path="/onboard/:type" component={Onboard} />
            <PrivateRoute
              exact
              path="/details/:type/:id"
              component={UpdateEnabler}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;
