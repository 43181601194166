import React, { useState } from "react";

function InitialImages(props) {
  const [img, setImg] = useState(props.props);
  return (
    <>
      {img &&
        img.map((val) => {
          if (
            (props.type === "subscriber" && val.user_id !== null) ||
            (props.type === "geofencer" && val.user_id === null)
          ) {
            return (
              <>
                <img
                  className="subscriber-property-initial-image cursor-pointer"
                  alt="initial property pic"
                  src={val.pictures}
                  onClick={() => {
                    props.setshowModal(true);
                    props.setmodalImage(val.pictures);
                  }}
                />
              </>
            );
          }
        })}
    </>
  );
}

export default InitialImages;
