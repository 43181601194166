import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import "./KYCRequests.css";

import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import KYCDetailsCard from "../../components/KYCDetailsCard/KYCDetailsCard";
import { getKYCRequests } from "../../modules/subscriber.modules";
import { changeActive } from "../../redux/features/sidebar.slice";

const KYCRequests = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [hasMore, setHasMore] = useState(true);
  const [key, setKey] = useState(0);
  const [kycReqs, setKycReqs] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    status: "pending",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    dispatch(changeActive("kyc"));
    try {
      getKYCRequests(
        user.user_id,
        0,
        searchParams,
        token,
        (error, kycRequests) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (kycRequests) {
            setKycReqs(kycRequests.data.rows);
            setTotal(kycRequests.data.count);
            if (kycRequests.data.count === 0 || kycRequests.data.count <= 10) {
              setHasMore(false);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleNameReset = () => {
    setSearchParams({ ...searchParams, name: "" });
  };

  const handleStartDateReset = () => {
    setSearchParams({ ...searchParams, startDate: "" });
  };

  const handleEndDateReset = () => {
    setSearchParams({ ...searchParams, endDate: "" });
  };

  const handleSearch = () => {
    if (
      (searchParams.startDate === "" && searchParams.endDate !== "") ||
      (searchParams.startDate !== "" && searchParams.endDate === "")
    ) {
      alert("Please provide To and From both dates");
      return;
    }

    setOffset(10);
    setHasMore(true);

    try {
      getKYCRequests(
        user.user_id,
        0,
        searchParams,
        token,
        (error, kycRequests) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (kycRequests) {
            setKycReqs(kycRequests.data.rows);
            setTotal(kycRequests.data.count);
            if (kycRequests.data.count === 0 || kycRequests.data.count <= 10) {
              setHasMore(false);
            }
          }
        }
      );

      setKey(key + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    if (kycReqs.length >= total) {
      setHasMore(false);
      return;
    }

    console.log("I ran", offset);
    try {
      getKYCRequests(
        user.user_id,
        offset,
        searchParams,
        token,
        (error, kycRequests) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (kycRequests) {
            setKycReqs([...kycReqs, ...kycRequests.data.rows]);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }

    setOffset(offset + 10);
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            <Container fluid className="main-page-content-container">
              <Row>
                <p className="kyc-title">KYC Requests</p>
                <Form>
                  <Row className="mb-4">
                    <Form.Group as={Col} xs={3}>
                      <Form.Label className="kyc-labels">Search by</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="name"
                          value={searchParams.name}
                          onChange={handleChange}
                          placeholder="Subscriber Name"
                        />
                        <InputGroup.Text
                          onClick={handleNameReset}
                          className="kyc-requests-clear-button"
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label className="kyc-labels">Status</Form.Label>
                      <Form.Select
                        onChange={handleChange}
                        name="status"
                        value={searchParams.status}
                        defaultValue={searchParams.status}
                      >
                        <option value="All">All</option>
                        <option value="pending">Pending</option>
                        <option value="accepted">Accepted</option>
                        <option value="rejected">Rejected</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} xs={3}>
                      <Form.Label className="kyc-labels">From Date</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="startDate"
                          value={searchParams.startDate}
                          onChange={handleChange}
                          type="text"
                          placeholder="DD/MM/YYYY"
                          onFocus={(e) => (e.target.type = "date")}
                          // onBlur={(e) => (e.target.type = "text")}
                        />
                        <InputGroup.Text
                          onClick={handleStartDateReset}
                          className="kyc-requests-clear-button"
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={3}>
                      <Form.Label className="kyc-labels">To Date</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="endDate"
                          value={searchParams.endDate}
                          onChange={handleChange}
                          type="text"
                          placeholder="DD/MM/YYYY"
                          onFocus={(e) => (e.target.type = "date")}
                          // onBlur={(e) => (e.target.type = "text")}
                        />
                        <InputGroup.Text
                          onClick={handleEndDateReset}
                          className="kyc-requests-clear-button"
                        >
                          x
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Col
                      xs={1}
                      className="kyc-requests-search-button-container"
                    >
                      <Button
                        className="kyc-requests-search-button"
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row xs={12}>
                <InfiniteScroll
                  dataLength={kycReqs.length}
                  key={key}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  height={450}
                  loader={<div class="lds-hourglass"></div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>End of results.</b>
                    </p>
                  }
                >
                  <div className="kyc-infinity-div">
                    {kycReqs.map((item, index) => (
                      <KYCDetailsCard
                        key={index}
                        details={item}
                        handleSearch={handleSearch}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
                <p className="mt-4">
                  Showing <b>{kycReqs.length}</b> results out of <b>{total}</b>
                </p>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KYCRequests;
